export default {
  navbar: {
    userGuide: 'Help & Guides',
  },
  settings: {
    emailTypes: {
      generalManager: 'General Manager',
      reception: 'Reception/Front Desk',
      sales: 'Revenue/Sales',
      email24: '24 Hour Email',
      notification: 'Notification',
      other: 'Other',
    },
  },
  auth: {
    emailAndPasswordDoNotMatchOurRecords: 'Email and password do not match our records',
    forgottenYourPassword: 'Forgotten your password?',
    forgottenYourUsername: 'Forgotten your username?',
    helpUsFindYourUsername:
      'Please provide the details requested in the form below to help us find your username.',
    helpUsResetYourPassword: 'Please provide us with your username to help us reset your password.',
    login: 'Login',
    logIn: 'Log in',
    logout: 'Logout',
    logOut: 'Log out',
    password: 'Password',
    passwordReset: 'Password reset',
    pleaseEnterThePrimaryEmailAddress: 'Please enter the primary email address for your account.',
    pleaseEnterYourUsername: 'Please enter your username...',
    pleaseEnterYourPassword: 'Please enter your password...',
    primaryEmailAddress: 'Primary Email Address',
    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'Unfortunately the system could not authenticate you.  This is usually because your session has expired.  Please log in again to continue.',
    username: 'Username',
    usernameReminder: 'Username reminder',
    youAreBeingLoggedOut: 'You are being logged out.',
    yourSessionHasExpired: 'Your session has expired.  Please log in again.',
  },
  availability: {
    availability: 'Availability',
    addAvailability: 'Add Availability',
    addingAvailability: 'Adding Availability',
    accessible: 'Accessible',
    double: 'Double',
    family: 'Family',
    single: 'Single',
    twin: 'Twin',
    room: 'Room',
    rooms: 'Rooms',
    numberAvailable: 'No. Available',
    noCmacRate: 'No CMAC Rate',
    rate: 'Rate',
    useCmacAgreedRates: 'Use CMAC Agreed Rates',
    fullyBooked: 'Fully Booked',
    helpText1: 'To enter availability, select a date or a range of dates.',
    helpText2: 'To select a date, click in the box for that date.',
    helpText3: 'To select a range, click a second, different box.',
    helpText4:
      'Selected dates will appear as a red items.  To clear a date that you have selected, click on the box for that date.',
    helpText5:
      'Click the Add Availability button and enter the total number of rooms available.  If you have submitted rates previously, we will use the CMAC agreed rates.  Otherwise, please enter the room only rate for the availability.',
    helpText6:
      'You can add multiple entries for the same date if you have rooms available at different rates.',
    publicHeader: 'CMAC Group - Availability Request - ',
    publicInstruction1: 'Please enter the number of each type you have available and click Submit',
    publicInstruction2:
      'You can always use this link to come back later and update this information. It will expire at',
    ratesIncludeTax: 'Please ensure the rate is INCLUSIVE of tax / vat.',
    submittedBy: 'Submitted by ',
    updatedBy: 'Updated by ',
    specificallyFor: 'Specifically for ',
    parkFlyRoomOnly: 'Sleep & Fly Room Only',
    parkFly4Bundle: 'Sleep & Fly Room + 4 Day Parking',
    parkFly8Bundle: 'Sleep & Fly Room + 8 Day Parking',
    parkFly15Bundle: 'Sleep & Fly Room + 15 Day Parking',
    parkFlyToCloseOut:
      'To close out a date please switch relevant items off. To re-enable booking you will need to re-upload your pricing spreadsheet.',
  },
  cmac: {
    actionedBy: 'Actioned by',
    addAddress: 'Add Address',
    addEmailAddress: 'Add Email Address',
    addTelephoneNumber: 'Add Telephone Number',
    addAComment: 'Add a comment',
    addComment: 'Add comment',
    addExpense: 'Add Expense',
    addingAComment:
      'Adding a comment will put this journey into dispute.  Please only use for differences not shown on the journey line. Please include full details, e.g., mileage and reason for query.',
    agreed: 'Agreed',
    allOpen: 'All open',
    amend: 'Amend',
    applies: 'Applies',
    apply: 'Apply',
    assignedOrganisation: 'Assigned organisation',
    availability: 'Availability',
    awaitingResponseFromSupplier: 'Awaiting Response From Supplier',
    billingAddress: 'Billing Address',
    bulkUpload: 'Bulk upload',
    bulkUploadHistory: 'Bulk upload history',
    cancelled: 'Cancelled',
    closed: 'Closed',
    cmac: 'CMAC',
    cmacReference: 'CMAC Reference',
    companyDetails: 'Company details',
    companyFinancialDetails: 'Financial details',
    companyOnBoardingDocuments: 'Company documents',
    companyNumber: 'Company number',
    compliance: 'Compliance',
    costConfirmation: 'Cost confirmation',
    costVatBreakDown: 'Cost VAT Break Down',
    coverage: 'Coverage',
    coverageAreas: 'Coverage areas',
    currentlyImpersonating: 'Currently impersonating',
    customDates: 'Custom Dates',
    customerName: 'Customer name',
    customerOrganisation: 'Customer organisation',
    departureDate: 'Departure date',
    depotAddress: 'Depot Address',
    dispute: 'Dispute',
    disputed: 'Disputed',
    disputeDetails: 'Dispute details',
    disputeEntry: 'Dispute entry',
    disputeEntries: 'Dispute entries',
    disputeId: 'Dispute ID',
    disputes: 'Disputes',
    disputesAwaitingAction: 'Disputes Awaiting Action',
    documentType: 'Document type',
    driver: 'Driver',
    driverDetails: 'Driver details',
    editAddress: 'Edit Address',
    editEmailAddress: 'Edit Email Address',
    editJourney: 'Edit Journey',
    editTelephoneNumber: 'Edit Telephone Number',
    editOpenJourneys: 'Edit open journeys',
    endImpersonation: 'End impersonation',
    endTime: 'End time',
    expenseItem: {
      meetAndGreet: 'Meet and Greet',
      parking: 'Parking',
      toll: 'Toll',
      greenFee: 'Green Fee',
      misc: 'Misc',
      amount: 'Amount',
    },
    expenses: 'Expenses',
    expired: 'Expired',
    expiryDate: 'Expiry date',
    expiryDateRequired: 'Expiry Date Required',
    extras: 'Extras',
    fare: 'Fare',
    farePriceCalculation: 'Fare price calculation',
    financialDetails: {
      accountName: 'Account Name',
      bankAddress: 'Bank Address',
      ibanNumber: 'IBAN Number',
      currency: 'Currency',
    },
    fixedPrices: 'Fixed prices',
    greenFee: 'Green fee',
    holidayRates: 'Holiday rates',
    holidays: 'Holidays',
    holidayUplift: 'Holiday Uplift',
    holidayUpliftDescription:
      'These custom dates apply to ALL rate card entries unless overridden by an Entry specific custom date',
    hotelMarkupRates: 'Hotel markup rates',
    hotelProfile: 'Hotel Profile',
    hotelRatesTitle: 'Hotel Rates',
    ifYouNeedToChangeHowWeKnowYouInOurSystemPleaseCallTheSupplierRelationsTeamOn:
      'If you need to change how we know you in our system please call the Supplier Relations Team on',
    impersonating: 'Impersonating',
    inProgress: 'In progress',
    invoiceId: 'Invoice ID',
    invoices: 'Invoices',
    job: 'Job | Jobs',
    jobEntryId: 'Job Entry ID',
    jobTypes: 'Job types',
    journey: 'Journey',
    journeys: 'Journeys',
    journeyChargeMethod: 'Journey charge method',
    lastUpdated: 'Last updated',
    leadMileage: 'Lead miles',
    leadMileageCost: 'Lead mileage cost',
    mileage: 'Mileage',
    mileageRates: 'Mileage rates',
    minimumPrice: 'Minimum price',
    noCustomDates: 'No custom dates',
    noOfPax: 'No of Pax',
    open: 'Open',
    orAlternativelyEmail: 'Or alternatively email',
    otherOrganisations: 'Other organisations',
    passenger: 'Passenger',
    parking: 'Parking',
    parkingExpenses: 'Parking expenses',
    pax: 'Pax',
    percentageOfOriginalCostPrice: 'Percentage of original cost price',
    permits: {
      currentHeading: 'CURRENT PERMITS',
      openHeading: 'OPEN FOR APPLICATION',
      title: 'Permits',
      claimed: 'Claimed',
      status: 'Status',
      expires: 'Expires',
      apply: 'Apply Now',
      outstandingAmendment: 'There is an outstanding amendment request for this permit.',
      noClaimedPermits: 'No claimed permits.',
      noOpenApplications: 'No open applications.',
      download: 'Download',
      print: 'Print Permit',
      amend: 'Amend',
    },
    pickupDateTime: 'Pickup Date/Time',
    pleaseAddAComment: 'Please add a comment',
    pleaseContactXForAssistance: 'Please contact {x} for assistance',
    pleaseSelectAXToImpersonate: 'Please select a {x} to impersonate',
    policiesAndTerms: 'Policies and terms',
    pricingInformation: 'Pricing information',
    processing: 'Processing',
    profile: 'Profile',
    psvarStatus: 'Psvar Status',
    rate: 'Rate',
    rateCards: 'Rate cards',
    referredToOperations: 'Referred to operations',
    referredToSupplierRelations: 'Referred to supplier relations',
    referredToPricingManager: 'Referred to pricing manager',
    regions: 'Regions',
    registration: 'Registration',
    responseReceivedFromSupplier: 'Response Received From Supplier',
    selectDocumentType: 'Select document type',
    selectUserToImpersonate: 'Select user to impersonate',
    standDownChargeMethod: 'Stand down charge method',
    standDownPrice: 'Stand down price',
    startTime: 'Start time',
    statementId: 'Statement ID',
    stoodDown: 'Stood down',
    stoodDownWithinXMinutesOfDepartureDate: 'Stood down within {x} minutes of departure date',
    summary: 'Summary',
    supplierName: 'Supplier name',
    supplierRef: 'Supplier Ref',
    termsAndConditions: 'Terms and conditions',
    thankYouForLoggingInToTheSupplierPortal: 'Thank you for logging in to the supplier portal',
    tierType: 'Tier type',
    updateFailed: 'Update failed',
    updateSuccessful: 'Update successful',
    userDetails: 'User details',
    hotelDetails: 'Hotel Details',
    vatNumber: 'VAT Number',
    vatTreatment: 'VAT Treatment',
    vehicle: 'Vehicle',
    vehicleRates: 'Vehicle rates',
    vias: 'Vias',
    viewAll: 'View all',
    waypoint: 'Waypoint | Waypoints',
    waitingTime: 'Waiting time',
    waitingTimeCost: 'Waiting time cost',
    waitingTimeFree: 'Waiting time free',
    waitingTimeMinutes: 'Waiting time minutes',
    waitingTimeRate: 'Waiting time rate',
    withinXMinutes: 'Within {x} minutes',
    vehicles: 'Vehicles',
    vehicleType: 'Vehicle type',
    vehicleRegistration: 'Vehicle Registration',
    yourAccountIsCurrentlyInactive: 'Your account is currently inactive',
    yourReference: 'Your reference',
    total: 'Total',
    invoiceuploads: 'Invoice Uploads',
    hotelRates: {
      validBetween: 'Valid between',
      addNewRate: 'Add Rates',
      addNewRateText: 'There is a new outstanding rate request for this organisation.',
      noRatesTextPrepend: 'There are no current rates for your organisation. Please contact us at',
      noRatesTextAppend: 'to arrange to submit your rates.',
      hasRatesText:
        'Below are the current rates we have in our system.  If you need to make any changes, please contact us at ',
      outstandingRateRequest: 'Outstanding Rate Request',
      profile: 'Profile',
      profileStarRating: 'Star Rating',
      profileUnkown: 'Unknown',
      profileYes: 'Yes',
      profileNo: 'No',
      profileYesFree: 'Yes Free',
      profileNumberOfRooms: 'Number of Rooms',
      profileAccessibleRooms: 'Accessible Rooms',
      profileFamilyRooms: 'Family Rooms',
      profileHotelBus: 'Hotel Bus',
      profileParking: 'Parking',
      profileSpa: 'Spa',
      profileSwimmingPool: 'Swimming Pool',
      profileGym: 'Gym',
      profileRestaurant: 'Restaurant',
      commissionRate: 'Commission Rates',
      commissionRooms: 'Rooms',
      commissionFood: 'Food',
      commissionDynamic: 'Dynamic',
      editText: 'Hotel Rate Card',
      editProfileText: 'Edit Hotel Details',
      ratesValidDatesText: 'Rates Are Applicable Between These Dates',
      ratesValidFrom: 'Valid From',
      ratesValidTo: 'Valid To',
      rates: 'Rates',
      ratesType: 'Type',
      ratesRoomText: 'Rates per room for delayed passengers and flight crew',
      ratesRoomType: 'Room Type',
      ratesMidweekText: 'Midweek Room only (Sun-Thu)',
      ratesDayLet: 'Day Let',
      ratesWeekendText: 'Weekend Room Only (Fri-Sat)',
      ratesRoomTwinDouble: 'Twin / Double',
      ratesRoomSingle: 'Single Room',
      ratesRoomTriple: 'Triple Room',
      ratesRoomFamily: 'Family',
      ratesWeekdayRate: 'Weekday Rate',
      ratesWeekdayDayRate: 'Weekday Day Rate',
      ratesWeekendRate: 'Weekend Rate',
      ratesWeekendDayRate: 'Weekend Day Rate',
      ratesFoodText: 'Food and beverages (inc tea, coffee, cordial) Price per person',
      ratesContinentalBreakfast: 'Continental Breakfast',
      ratesFullBreakfast: 'Full Breakfast',
      ratesLunch: 'Lunch',
      ratesDinner: 'Dinner',
      ratesAgreementText:
        'Entering these rates will create a binding agreement between CMAC Group and your hotel and as such you should ensure that only an authorised individual completes the form. The hotel shall be responsible for the individual who completes the form and if an unauthorised individual completes the form this shall not impact CMAC Group or their ability to enforce these rates.',
      ratesTermsConditionsText: 'Please tick to confirm your have read our ',
      ratesTermsConditionsLinkText: ' terms and conditions.',
      viewRatesTermsConditionsText: 'Click here to view our',
      submittedHeading: 'Submitted By',
    },
    cmacRef: 'CMAC Ref',
    yourRef: 'Your Ref',
    noNotificationEmailAddress: 'You have no notification email address',
    leadMiles: 'Lead Miles',
    fareNet: 'Fare (Net)',
    leadMilesCost: 'Lead Miles Cost (Net)',
    totalCost: 'Total Cost (Net)',
    appliesOnDays: 'Applies on days',
    noVehicleRates: 'No vehicle rates',
    markup: 'Markup',
    addedPrice: 'Added Price',
    noHolidayRates: 'No holiday rates',
    standDownRates: 'Stand Down Rates',
    noStandDownRates: 'No stand down rates',
    stoodDownWithin: 'Stood down within',
    minutesOfDepartureDate: 'minutes of departure date',
    ofOriginalCostPrice: 'of original cost price',
    ofOriginalCostPriceWhicheverIsHigher: 'of original cost price, whichever is higher',
    waitingTimeRates: 'Waiting Time Rates',
    noWaitingTimeRates: 'No waiting time rates',
    minutesFree: 'minutes free',
    minutes: 'minutes',
  },
  common: {
    about: 'About',
    accepted: 'Accepted',
    addComment: 'Add Comment',
    add: 'Add',
    addVehicle: 'Add Vehicle',
    actions: 'Actions',
    addXtoY: 'Add {x} to {y}',
    agreed: 'Agreed',
    agreementType: 'Agreement Type',
    all: 'All',
    back: 'Back',
    backToTop: 'Back to top',
    cancel: 'Cancel',
    close: 'Close',
    comment: 'Comment',
    comments: 'Comments',
    completed: 'Completed',
    confirm: 'Confirm',
    continue: 'Continue',
    cost: 'Cost',
    create: 'Create',
    created: 'Created',
    createdAt: 'Created at',
    customer: 'Customer',
    date: 'Date',
    dateRange: 'Date Range',
    delete: 'Delete',
    departureDateRange: 'Departure Date Range',
    description: 'Description',
    details: 'Details',
    disputed: 'Disputed',
    distance: 'Distance',
    documents: 'Documents',
    download: 'Download',
    edit: 'Edit',
    edited: 'Edited',
    editVehicle: 'Edit Vehicle',
    email: 'Email',
    enabled: 'enabled',
    error: 'Error',
    example: 'Example',
    expired: 'Expired',
    expires: 'Expires',
    failed: 'Failed',
    file: 'File',
    filename: 'File Name',
    filters: 'Filters',
    from: 'From',
    full: 'Full',
    half: 'Half',
    home: 'Home',
    hide: 'Hide',
    id: 'Id',
    info: 'Info',
    important: 'Important',
    jobNo: 'Job No',
    loading: 'Loading',
    map: 'Map',
    messages: 'Messages',
    miles: 'Miles',
    minutes: 'Minutes',
    mode: 'Mode',
    name: 'Name',
    next: 'Next',
    new: 'New',
    no: 'No',
    none: 'None',
    notes: 'Notes',
    null: 'Null',
    ok: 'OK',
    open: 'Open',
    organisation: 'Organisation',
    organisations: 'Organisations',
    original: 'Original',
    others: 'Others',
    password: 'Password',
    pending: 'Pending',
    price: 'Price',
    previouslyUploaded: 'Previously Uploaded',
    processed: 'Processed',
    processing: 'Processing',
    protection: 'Protection',
    quantity: 'Quantity',
    raw: 'Raw',
    readonly: 'Readonly',
    reference: 'Reference',
    refresh: 'Refresh',
    reset: 'Reset',
    roles: 'Roles',
    save: 'Save',
    search: 'Search',
    settings: 'Settings',
    show: 'Show',
    signedBy: 'Signed By',
    signedOn: 'Signed On',
    sortBy: 'Sort by',
    status: 'Status',
    submit: 'Submit',
    surcharge: 'Surcharge',
    test: 'Test',
    time: 'Time',
    title: 'Title',
    to: 'To',
    total: 'Total',
    type: 'Type',
    unknown: 'Unknown',
    update: 'Update',
    updated: 'Updated',
    updatedAt: 'Updated at',
    upload: 'Upload',
    uploaded: 'Uploaded',
    user: 'User',
    username: 'Username',
    users: 'Users',
    website: 'Website',
    weight: 'Weight',
    yes: 'Yes',
    hour: 'hour',
    mile: 'mile',
    or: 'or',
  },
  currencies: {
    gbp: '£',
    eur: '€',
  },
  datetime: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    today: 'Today',
  },
  docs: {
    documentation: 'Documentation',
    faq: 'FAQ',
    frequentlyAskedQuestions: 'Frequently asked questions',
    tutorials: 'Tutorials',
  },
  errors: {
    301: 'You are not authorised to access this resource.',
    400: 'Sorry, the request was unsuccessful.',
    404: 'Sorry, we could not find the resource you were looking for.',
    500: 'The server could not process the request.',
    error: 'Error',
    nError: '{n} error',
    noResponseFromServer: 'No response from server',
    noUrl: 'No url',
    sorrySomethingWentWrong: 'Sorry, something went wrong',
    uploadFailed: 'Upload failed',
    uploadFailedWithError: 'Upload failed, with error',
  },
  fields: {
    address: 'Address',
    addresses: 'Addresses',
    addressLine1: 'Address line 1',
    addressLine2: 'Address line 2',
    addressLine3: 'Address line 3',
    addressLookup: 'Address Lookup',
    city: 'City',
    country: 'Country',
    county: 'County',
    description: 'Description',
    driverName: 'Driver Name',
    driverPhone: 'Driver Phone Number',
    driverRegistration: 'Driver Registration',
    email: 'Email',
    emailAddress: 'Email Address',
    emailAddresses: 'Email addresses',
    home: 'Home',
    mobile: 'Mobile',
    name: 'Name',
    number: 'Number',
    phoneNumber: 'Phone number',
    postCode: 'Post code',
    region: 'Region',
    registrationNumber: 'Registration number',
    telephoneNumber: 'Telephone Number',
    telephoneNumbers: 'Telephone numbers',
    title: 'Title',
    titles: {
      dr: 'Dr',
      lady: 'Lady',
      lord: 'Lord',
      mr: 'Mr',
      miss: 'Miss',
      mrs: 'Mrs',
      ms: 'Ms',
      mx: 'Mx',
      prof: 'Prof',
      sir: 'Sir',
    },
    town: 'Town',
    work: 'Work',
  },
  onboarding: {
    documents: {
      public_insurance: 'Public Liability Insurance certificate',
      liability: 'Employers Liability Insurance certificate',
      motor_insurance: 'Motor Insurance certificate',
      gdpr: 'GDPR Policy',
      small_operator_license: "Operator's License - small vehicles",
      large_operator_license: "Operator's License - large vehicles",
      upload: 'Upload document',
      download: 'Download document',
      file: 'File name',
      document: 'Document type',
      created: 'Created on',
      expires: 'Expires on',
    },
    agreement: 'I have read and I accept the terms and conditions printed above.',
    mustAgree: 'You must accept the terms and conditions to use the app.',
    welcomeHeader: 'Welcome to CMAC supplier signup',
    progressCompleted:
      'Thank you for submitting all of your documents.  One of our team will now review your submission and contact you shortly to progress to the next step.',
    country: 'Country name',
    legalCompanyName: 'Legal company name',
    tradingName: 'Trading name',
    companyRegistrationNumber: 'Company registration number',
    registeredAddress: 'Registered / Legal Office',
    operatingAddress: 'Operating Address',
    currency: 'Logout',
    logOut: 'Log out',
    smallVehicle: 'Small Vehicle (Taxi, MPV, WAV, Black Cab)',
    largeVehicle: 'Large Vehicle (Minibus, Coach)',
    fleetSize: 'Fleet size',
    emailAddress: 'Email address',
    mainContactNumber: 'Please enter your password...',
    primaryEmailAddress: 'Primary Email Address',
    successResult:
      'Your account has been created successfully. You will shortly receive an email with details of how to set your password to log in to this site.',
    failResult:
      'We are unable to create to create your account at this time. We have an existing account that matches some of the information you have provided. Please contact supplier.relations@cmacgroup.com for more information.',

    progressHeader: 'Thank you for submitting your details to become a CMAC Supplier.',
    progressMainText:
      'To progress with your application, please ensure that all parts of the Financial Details and Company Documents sections below are completed.',
    progressSubText:
      'Once we have received this information, we will be able to move your application to the next stage.',
    progressBarText: 'Progress',
    rejected:
      'Regrettably, your application has not been successful.  Please contact our supplier relations team for further information.',
  },
  plurals: {
    comments: 'Comment | Comments',
    journeys: 'Journey| Journeys',
    results: 'Result | Results',
    resultsFound: 'Result found | Results found',
    rows: 'Row | Rows',
    suppliers: 'Supplier | Suppliers',
  },
  ui: {
    anNewVersionOfTheApplicationIsAvailable: 'An new version of the application is available',
    appName: 'Supplier portal',
    approve: 'Approve',
    approveAllSelectedRows: 'Approve all selected rows',
    changeProfilePicture: 'Change profile picture',
    chooseX: 'Choose {x}',
    clear: 'Clear',
    clearFilters: 'Clear filters',
    clickToCopyToClipboard: 'Click to copy to clipboard',
    clickToSortByX: 'Click to sort by {x}',
    closeDrawer: 'Close drawer',
    currentlyUnavailable: 'Currently unavailable',
    dashboard: 'Dashboard',
    document: 'Document',
    dragYourXHereToUploadIt: 'Drag your {x} here to upload it',
    export: 'Export',
    exportToCsv: 'Export to CSV',
    file: 'file',
    files: 'Files',
    fileSizeTooBig: 'File size too big',
    footer: 'Footer',
    home: 'Home',
    homePage: 'Home Page',
    itemsPerPage: 'Items per page',
    navigation: 'Navigation',
    noDetailsToDisplay: 'No details to display',
    noFileSelected: 'No file selected',
    noResultsToShow: 'No results to show',
    noXProvided: 'No {x} provided',
    orClickToBrowse: 'Or click to browse',
    page: 'Page',
    pageSize: 'Page size',
    pleaseSeletFromTheFollowing: 'Please selet from the following',
    pleaseSelectAnX: 'Please select an {x}',
    pleaseSelectAX: 'Please select a {x}',
    pleaseSelectNXOnly: 'Please select {n} {x} only',
    profilePicture: 'Profile picture',
    resultsPerPage: 'Results per page',
    saveChanges: 'Save changes',
    selectAll: 'Select all',
    selectX: 'Select {x}',
    selectADifferentX: 'Select a different {x}',
    spreadsheet: 'Spreadsheet',
    theXHasBeenDeleted: 'The {x} has been deleted',
    thisWillPutXInDispute: 'This will put {x} in dispute',
    updateX: 'Update {x}',
    uploadBeingProcessed: 'Upload is being processed',
    uploadStatus: 'Upload status',
    uploading: 'Uploading',
    uploadNewX: 'Upload new {x}',
    uploadX: 'Upload {x}',
    userProfile: 'User profile',
    version: 'version',
    xMissing: '{x} missing',
    policySignedX: 'Thank you for signing {x}',
  },
  validations: {
    canOnlyContainLettersAndNumbers: 'Can only contain letters and numbers',
    mustBeUkBankSortCode: 'Sort code must be in a format of 00-00-00',
    mustBeUkBankAccountNumber: 'UK bank account number must be 8 digits code',
    canOnlyContainLettersNumbersAndTheFollowingCharactersX:
      'Can only contain letters, numbers and he following characters: {x}',
    canOnlyContainNumbers: 'Can only contain numbers',
    checkboxConfirm: 'Please check the box to confirm you accept these terms and conditions',
    maxNCharacters: 'Max {n} characters',
    minNCharacters: 'Min {n} characters',
    mustBeAnInteger: 'Must be an integer',
    mustBeAFloat: 'Must be a float',
    mustBeAValidEmailAddress: 'Must be a valid email address',
    mustBeAValidTelephoneNumber: 'Must be a valid telephone number',
    mustBeAWholeNumber: 'Must be a whole number',
    mustBeExactlyNCharacters: 'Must be exactly {n} characters',
    mustBeGreaterThanN: 'Must be greater than {n}',
    mustBeGreaterThanOrEqualToN: 'Must be greater than or equal to {n}',
    mustBeLessThanN: 'Must be less than {n}',
    mustBeLessThanOrEqualToN: 'Must be less than or equal to {n}',
    mustBeRoundedToExactlyNDecimalPlaces: 'Must be rounded to exactly {n} decimal places',
    mustBeRoundedToNDecimalPlaces: 'Must be rounded to {n} decimal places',
    mustHaveASingleDecimalPoint: 'Must have a single decimal point',
    mustBeAtLeastOneChecked: 'Check one or both of the values',
    requiresBooleanValue: 'Requires boolean value',
    thisDateMustBeInTheFuture: 'This date must be in the future',
    thisDateMustBeInThePast: 'This date must be in the past',
    thisExceedsTheLeadMileageLimit: 'This exceeds the lead mileage limit',
    thisFieldIsRequired: 'This field is required',
    thisFieldMustBeAValidDate: 'This field must be a valid date',
    thisFieldMustContainAX: 'This field must contain a {x}',
    thisFieldMustContainAnX: 'This field must contain an {x}',
  },
  invoiceUpload: {
    invoiceNo: 'Invoice No',
    reference: 'Reference',
    uploadDate: 'Upload Date',
    invoiceDate: 'Invoice Date',
    dueDate: 'Due Date',
    total: 'Total',
    currency: 'Currency',
    status: 'Status',
    comments: 'Comments',
    file: 'File',
    selectJourneys: 'Select Journeys',
    uploadInvoice: 'Upload Invoice',
    confirm: 'Confirm',
    uploadingAs: 'You are currently uploading as',
    atLeastOneJourney: 'You must select at least one journey',
    errorUploadingFile: 'There was an error uploading and analysing the file.',
    uploadInFormat: 'Please upload your invoice in PDF format.',
    selectFile: 'Please select a file for upload',
    uploadingAnalyzing: 'Uploading and analyzing',
    invoiceReference: 'Invoice Reference',
    invoice: 'Invoice',
    cmacReference: 'CMAC Reference',
    yourReference: 'Your Reference',
    journeysSelectionJourney: 'journey | journeys',
    journeySelectionSelected: 'selected',
    net: 'Net',
    tax: 'Tax',
    gross: 'Gross',
    expenses: 'Expenses',
    details: 'Details',
    journey: 'Journey',
    departureDate: 'Departure Date',
    cmacRef: 'CMAC Ref',
    yourRef: 'Your Ref',
    invoiceAccepted: 'Invoice validated and accepted',
    invoiceDoesNotMatch: 'Please review and re-submit',
    stillUpload: 'You can still upload this invoice but it will require investigation. ',
    paymentDelayed: 'Payment will be delayed until this is complete.',
    totalNetDoesNotMatch:
      'The Total Net amount on the invoice does not match the total net price for all selected journeys.',
    expected: 'Expected',
    totalGross: 'Total Gross',
    totalNet: 'Total Net',
    notFound: 'Not Found',
    invoiced: 'Invoiced',
    underDispute: 'Under Dispute',
    notUnique: 'A file with this name already exists.  Please choose a different file.',
    invalidCharacters: 'The file name contains invalid characters. The following are not allowed.',
    invalidFileExtension:
      'This type of file is not valid. The following types of file are allowed.',
    cancelled: 'Cancelled',
    journeyCost: 'Journey Cost (Net)',
    leadMilesCost: 'Lead Miles Cost (Net)',
    waitingTimeCost: 'Waiting Time Cost (Net)',
    expensesCost: 'Expenses Cost (Net)',
    greenFeeCost: 'Green Fee Cost (Net)',
    totalCost: 'Total Cost (Net)',
    totalTax: 'Total VAT',
    downloadJourneyData: 'Click here to download the data for the selected journeys.',
    paid: 'Paid',
    excludedJourneys: 'This process excludes journeys that are currently in dispute.',
    rejectedStatus: 'Rejected',
    createPdfInvoice: 'Create PDF Invoice',
    createPdf: {
      title: 'Create PDF invoice for selected journeys',
      invoiceNumber: 'Invoice Number',
      invoiceDate: 'Invoice Date',
      chooseAddress: 'Please choose address',
      registerDetails: {
        title: 'Register Details',
        heading: 'To create your invoice, we require some additional information.',
        financial: {
          heading: 'Please follow the steps below to add your financial details:',
          step1: 'Select the hamburger menu icon {x} located at the top right of your screen',
          step2: "Then select 'Profile'.",
          step3:
            "In the 'Financial Details' section, click on the edit button {x} and enter your details.",
        },
        address: {
          heading: 'Please follow the steps below to add your company address:',
          step1: 'Select the hamburger menu icon {x} located at the top right of your screen.',
          step2: "Then select 'Profile'.",
          step3: "In the 'Addresses' section, click on the plus button {x} and enter your details.",
        },
      },
    },
    separateInvoicesRequired:
      'Journeys with vehicles with more than 10 seats need to be invoiced separately to journeys with vehicles of less than 10 seats.',
    invoiceNumberFieldNameLabel: 'Invoice Number Field Name',
    totalNetAmountFieldNameLabel: 'Total Net Amount Field Name',
  },
  compliance: {
    introduction:
      'Below is a summary of the key documents that must be in place and registered with us. If you have any question regarding any of these documents, please contact us on 03333 207 100',
    noRulesFound: 'No compliance rules found',
  },
  contactItem: {
    generalManager: 'General Manager',
    reception24Hour: 'Reception/Front Desk',
    revenueSales: 'Revenue/Sales',
    twentyFourHourEmail: '24 Hour Email',
    notification: 'Notification',
    other: 'Other',
  },
}
