export default {
  navbar: {
    userGuide: 'Hulp & Handleidingen',
  },
  settings: {
    emailTypes: {
      generalManager: 'Algemeen Directeur',
      reception: 'Receptie/Balie',
      sales: 'Omzet/Verkoop',
      email24: '24-uurs E-mail',
      notification: 'Melding',
      other: 'Overig',
    },
  },
  auth: {
    emailAndPasswordDoNotMatchOurRecords:
      'E-mail en wachtwoord komen niet overeen met onze gegevens',
    forgottenYourPassword: 'Wachtwoord vergeten?',
    forgottenYourUsername: 'Gebruikersnaam vergeten?',
    helpUsFindYourUsername:
      'Geef de gevraagde gegevens in het onderstaande formulier op om ons te helpen uw gebruikersnaam te vinden.',
    helpUsResetYourPassword:
      'Geef ons uw gebruikersnaam om ons te helpen uw wachtwoord opnieuw in te stellen.',
    login: 'Inloggen',
    logIn: 'Inloggen',
    logout: 'Uitloggen',
    logOut: 'Uitloggen',
    password: 'Wachtwoord',
    passwordReset: 'Wachtwoord reset',
    pleaseEnterThePrimaryEmailAddress: 'Voer het primaire e-mailadres voor uw account in.',
    pleaseEnterYourUsername: 'Voer uw gebruikersnaam in...',
    pleaseEnterYourPassword: 'Voer uw wachtwoord in...',
    primaryEmailAddress: 'Primair e-mailadres',
    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'Helaas kon het systeem u niet authenticeren. Dit komt meestal doordat uw sessie is verlopen. Log opnieuw in om verder te gaan.',
    username: 'Gebruikersnaam',
    usernameReminder: 'Gebruikersnaam herinnering',
    youAreBeingLoggedOut: 'U wordt uitgelogd.',
    yourSessionHasExpired: 'Uw sessie is verlopen. Log opnieuw in.',
  },
  availability: {
    availability: 'Beschikbaarheid',
    addAvailability: 'Beschikbaarheid toevoegen',
    addingAvailability: 'Bezig met toevoegen beschikbaarheid',
    accessible: 'Toegankelijk',
    double: 'Tweepersoonskamer met dubbel bed',
    family: 'Gezin',
    single: 'Eenpersoonskamer',
    twin: 'Kamer met twee eenpersoonsbedden',
    room: 'Kamer',
    rooms: 'Kamers',
    numberAvailable: 'Aantal beschikbaar',
    noCmacRate: 'Geen CMAC-tarief',
    rate: 'Tarief',
    useCmacAgreedRates: 'CMAC-overeengekomen tarieven gebruiken',
    fullyBooked: 'Volgeboekt',
    helpText1: 'Selecteer een datum of een reeks datums om beschikbaarheid in te voeren.',
    helpText2: 'Om een datum te selecteren, klikt u in het vak voor die datum.',
    helpText3: 'Klik op een tweede, ander vak om een reeks te selecteren.',
    helpText4:
      'Geselecteerde datums verschijnen als rode items. Om een door u geselecteerde datum te wissen, klikt u op het vakje voor die datum.',
    helpText5:
      'Klik op de knop Beschikbaarheid toevoegen en voer het totale aantal beschikbare kamers in. Indien u eerder tarieven heeft opgegeven, hanteren wij de CMAC overeengekomen tarieven. Voer anders het tarief voor alleen de kamer in voor de beschikbaarheid.',
    helpText6:
      'U kunt meerdere vermeldingen voor dezelfde datum toevoegen als u kamers beschikbaar heeft tegen verschillende tarieven.',
    publicHeader: 'CMAC Group - Beschikbaarheidsaanvraag - ',
    publicInstruction1:
      'Voer het aantal in van elk type dat u beschikbaar heeft en klik op Indienen',
    publicInstruction2:
      'U kunt deze link altijd gebruiken om later terug te komen en deze informatie bij te werken. Dit zal verlopen op',
    ratesIncludeTax: 'Zorg ervoor dat het tarief INCLUSIEF belasting/btw is.',
    submittedBy: 'Innsendt av ',
    updatedBy: 'Oppdatert av ',
    specificallyFor: 'Specifiek voor ',
    parkFlyRoomOnly: 'Alleen slaap- en vliegruimte',
    parkFly4Bundle: 'Slaap- en vliegkamer + 4 dagen parkeren',
    parkFly8Bundle: 'Slaap- en vliegkamer + 8 dagen parkeren',
    parkFly15Bundle: 'Sleep & Fly Room + 15 dagen parkeren',
    parkFlyToCloseOut:
      'Om een datum af te sluiten, schakelt u relevante items uit. Om de boeking weer in te schakelen, moet u uw prijsspreadsheet opnieuw uploaden.',
  },
  cmac: {
    actionedBy: 'Uitgevoerd door',
    addAddress: 'Adres Toevoegen',
    addEmailAddress: 'E-mailadres Toevoegen',
    addTelephoneNumber: 'Telefoonnummer Toevoegen',
    addAComment: 'Voeg een opmerking toe',
    addComment: 'Voeg opmerking toe',
    addExpense: 'Uitgave Toevoegen',
    addingAComment:
      'Als u een opmerking toevoegt, wordt deze reis in geschil geplaatst.  Alleen gebruiken voor verschillen die niet op de reisregel staan. Geef alle details op, bijvoorbeeld de kilometerstand en de reden voor de vraag.',
    agreed: 'Overeengekomen',
    allOpen: 'Alle open',
    amend: 'Wijzigen',
    applies: 'Van toepassing',
    apply: 'Toepassen',
    assignedOrganisation: 'Toegewezen organisatie',
    availability: 'Beschikbaarheid',
    awaitingResponseFromSupplier: 'Wacht op antwoord van leverancier',
    billingAddress: 'Factuuradres',
    bulkUpload: 'Bulk upload',
    bulkUploadHistory: 'Bulk upload geschiedenis',
    cancelled: 'Geannuleerd',
    closed: 'Afgesloten',
    cmac: 'CMAC',
    cmacReference: 'CMAC-referentie',
    companyDetails: 'Bedrijfsgegevens',
    companyFinancialDetails: 'Financiële gegevens',
    companyOnBoardingDocuments: 'On-boarding documenten bedrijf',
    companyNumber: 'Bedrijfsnummer',
    compliance: 'Naleving',
    costConfirmation: 'Kostenbevestiging',
    costVatBreakDown: 'Kosten BTW-uitsplitsing',
    coverage: 'Dekking',
    coverageAreas: 'Dekking regio’s',
    currentlyImpersonating: 'Imiteert momenteel',
    customDates: 'Aangepaste datums',
    customerName: 'Naam klant',
    customerOrganisation: 'Klantenorganisatie',
    departureDate: 'Vertrekdatum',
    depotAddress: 'Depotadres',
    dispute: 'Betwisten',
    disputed: 'Betwist',
    disputeDetails: 'Geschil details',
    disputeEntry: 'Geschil invoer',
    disputeEntries: 'Geschil invoeren',
    disputeId: 'Geschil ID',
    disputes: 'Geschillen',
    disputesAwaitingAction: 'Geschillen Wachtend op Actie',
    documentType: 'Documenttype',
    driver: 'Bestuurder',
    driverDetails: 'Gegevens bestuurder',
    editAddress: 'Adres Bewerken',
    editEmailAddress: 'E-mailadres Bewerken',
    editJourney: 'Reis bewerken',
    editTelephoneNumber: 'Telefoonnummer Bewerken',
    editOpenJourneys: 'Open reizen bewerken',
    endImpersonation: 'Beëindig nabootsing van identiteit',
    endTime: 'Eindtijd',
    expenseItem: {
      meetAndGreet: 'Ontmoeting en begroeting',
      parking: 'Parkeren',
      toll: 'Tol',
      greenFee: 'Milieutoeslag',
      misc: 'Diversen',
      amount: 'Bedrag',
    },
    expenses: 'Onkosten',
    expired: 'Verlopen',
    expiryDate: 'Vervaldatum',
    expiryDateRequired: 'Vervaldatum verplicht',
    extras: 'Extra’s',
    fare: 'Tarief',
    farePriceCalculation: 'Berekening tarief prijs',
    financialDetails: {
      accountName: 'Rekeningnaam',
      bankAddress: 'Bankadres',
      ibanNumber: 'IBAN Nummer',
      currency: 'Valuta',
    },
    fixedPrices: 'Vaste prijzen',
    greenFee: 'Eco-belasting',
    holidayRates: 'Feestdagtarieven',
    holidays: 'Officiële feestdagen',
    holidayUplift: 'Vakantie Uplift',
    holidayUpliftDescription:
      'Deze aangepaste datums zijn van toepassing op ALLE tariefkaartinzendingen, tenzij ze worden overschreven door een specifieke aangepaste datum voor invoer',
    hotelMarkupRates: 'Hotel opslagtarieven',
    hotelProfile: 'Hotelprofiel',
    hotelRatesTitle: 'Hoteltarieven',
    ifYouNeedToChangeHowWeKnowYouInOurSystemPleaseCallTheSupplierRelationsTeamOn:
      'Als u wilt wijzigen hoe we u in ons systeem kennen, bel dan het Supplier Relations Team op',
    impersonating: 'Nabootsen',
    inProgress: 'Bezig',
    invoiceId: 'Factuur-ID',
    invoices: 'Facturen',
    job: 'Opdracht | Opdrachten',
    jobEntryId: 'Opdracht invoer-ID',
    jobTypes: 'Opdrachttypes',
    journey: 'Reis',
    journeys: 'Reizen',
    journeyChargeMethod: 'Wijze van reiskostenvergoeding',
    lastUpdated: 'Laatst bijgewerkt',
    leadMileage: 'Extra kilometers',
    leadMileageCost: 'Kost extra kilometers',
    mileage: 'Kilometerstand',
    mileageRates: 'Kilometer tarieven',
    minimumPrice: 'Minimumprijs',
    noCustomDates: 'Geen aangepaste datums',
    noOfPax: 'Aant. pass.',
    open: 'Open',
    orAlternativelyEmail: 'Of stuur een e-mail',
    otherOrganisations: 'Andere organisaties',
    passenger: 'Passagier',
    parking: 'Parking',
    parkingExpenses: 'Parkeerkosten',
    pax: 'Passagiers',
    percentageOfOriginalCostPrice: 'Percentage van oorspronkelijke kostprijs',
    permits: {
      currentHeading: 'HUIDIGE VERGUNNINGEN',
      openHeading: 'OPEN VOOR AANVRAAG',
      title: 'Vergunningen',
      claimed: 'Geclaimd',
      status: 'Status',
      expires: 'Verloopt',
      apply: 'Nu aanvragen',
      outstandingAmendment: 'Er is een uitstaand wijzigingsverzoek voor deze vergunning.',
      noClaimedPermits: 'Geen geclaimde vergunningen.',
      noOpenApplications: 'Geen open aanvragen.',
      download: 'Downloaden',
      print: 'Vergunning afdrukken',
      amend: 'Wijzigen',
    },
    pickupDateTime: 'Ophaaldatum/tijd',
    pleaseAddAComment: 'Voeg een opmerking toe',
    pleaseContactXForAssistance: 'Neem contact op met {x} voor assistentie',
    pleaseSelectAXToImpersonate: 'Please select a {x} to impersonate',
    policiesAndTerms: 'Beleid en voorwaarden',
    pricingInformation: 'Prijszettingsinformatie',
    processing: 'Bezig met verwerken',
    profile: 'Profiel',
    psvarStatus: 'Psvar Status',
    rate: 'Tarief',
    rateCards: 'Tariefkaarten',
    referredToOperations: 'Verwezen naar operaties',
    referredToSupplierRelations: 'Verwezen naar leveranciersrelaties',
    referredToPricingManager: 'Verwezen naar de prijs manager',
    regions: 'Regio’s',
    registration: 'Nummerplaat',
    responseReceivedFromSupplier: 'Antwoord ontvangen van leverancier',
    selectDocumentType: 'Selecteer documenttype',
    selectUserToImpersonate: 'Select user to impersonate',
    standDownChargeMethod: 'Aanrekenmethode wanneer klant niet opdaagt',
    standDownPrice: 'Prijs voor niet komen opdagen',
    startTime: 'Starttijd',
    statementId: 'Overzicht ID',
    stoodDown: 'Niet komen opdagen',
    stoodDownWithinXMinutesOfDepartureDate: 'Binnen {x} minuten na vertrekdatum niet komen opdagen',
    summary: 'Samenvatting',
    supplierName: 'Naam leverancier',
    supplierRef: 'Lever. Ref.',
    termsAndConditions: 'Algemene voorwaarden',
    thankYouForLoggingInToTheSupplierPortal: 'Bedankt voor het inloggen op het leveranciersportaal',
    tierType: 'Type niveau',
    updateFailed: 'Update mislukt',
    updateSuccessful: 'Update geslaagd',
    userDetails: 'Gebruikersgegevens',
    hotelDetails: 'Hotelgegevens',
    vatNumber: 'BTW Nummer',
    vatTreatment: 'BTW Behandeling',
    vehicle: 'Voertuig',
    vehicleRates: 'Voertuigtarieven',
    vias: 'Via’s',
    viewAll: 'Alle weergeven',
    waypoint: 'Tussenpunt | Tussenpunten',
    waitingTime: 'Wachttijd',
    waitingTimeCost: 'Kost wachttijd',
    waitingTimeFree: 'Vrij wachttijd',
    waitingTimeMinutes: 'Wachttijd minuten',
    waitingTimeRate: 'Wachttijd tarief',
    withinXMinutes: 'Binnen {x} minuten',
    vehicles: 'Voertuigen',
    vehicleRegistration: 'Voertuig Registratie',
    vehicleType: 'Type voertuig',
    yourAccountIsCurrentlyInactive: 'Uw account is momenteel inactief',
    yourReference: 'Uw referentie',
    total: 'Totaal',
    invoiceuploads: 'Factuuruploads',
    hotelRates: {
      validBetween: 'Geldig tussen',
      addNewRate: 'Tarieven toevoegen',
      addNewRateText: 'Er is een nieuw uitstaand tariefverzoek voor deze organisatie.',
      noRatesTextPrepend:
        'Er zijn geen actuele tarieven voor uw organisatie. Neem contact op met ons op',
      noRatesTextAppend: 'om het inzenden van uw tarieven te regelen.',
      hasRatesText:
        'Hieronder ziet u de huidige tarieven die we in ons systeem hebben.  Als u wijzigingen moet doorvoeren, neemt u contact op met ons op ',
      profile: 'Profiel',
      profileStarRating: 'Sterrenclassificatie',
      profileUnkown: 'Onbekend',
      profileYes: 'Ja',
      profileNo: 'Nee',
      profileYesFree: 'Ja gratis',
      profileNumberOfRooms: 'Aantal kamers',
      profileAccessibleRooms: 'Toegankelijke kamers',
      profileFamilyRooms: 'Gezinskamers',
      profileHotelBus: 'Hotel Bus',
      profileParking: 'Parking',
      profileSpa: 'Spa',
      profileSwimmingPool: 'Zwembad',
      profileGym: 'Gym',
      profileRestaurant: 'Restaurant',
      commissionRate: 'Commissietarieven',
      commissionRooms: 'Kamers',
      commissionFood: 'Eten',
      commissionDynamic: 'Dynamisch',
      editText: 'Hotel tariefkaart',
      editProfileText: 'Hotelgegevens bewerken',
      ratesValidDatesText: 'Tarieven zijn van toepassing tussen deze data',
      ratesValidFrom: 'Geldig van',
      ratesValidTo: 'Geldig tot',
      rates: 'Tarieven',
      ratesType: 'Type',
      ratesRoomText: 'Tarieven per kamer voor passagiers en cockpitpersoneel met vertraging',
      ratesRoomType: 'Type kamer',
      ratesMidweekText: 'Alleen midweekkamer (zo-do)',
      ratesDayLet: 'Dagtarief',
      ratesWeekendText: 'Alleen weekend kamer (vrij-zat)',
      ratesRoomTwinDouble: 'Twee eenpersoonsbedden/dubbel bed',
      ratesRoomSingle: 'Eenpersoonskamer',
      ratesRoomTriple: 'Driepersoonskamer',
      ratesRoomFamily: 'Gezin',
      ratesWeekdayRate: 'Tarief weekdag',
      ratesWeekdayDayRate: 'Dagtarief weekdag',
      ratesWeekendRate: 'Weekendtarief',
      ratesWeekendDayRate: 'Weekend dagtarief',
      ratesFoodText: 'Eten en drinken (incl thee, koffie, limonade) Prijs per persoon',
      ratesContinentalBreakfast: 'Continentaal ontbijt',
      ratesFullBreakfast: 'Volledig ontbijt',
      ratesLunch: 'Lunch',
      ratesDinner: 'Avondmaal',
      ratesAgreementText:
        'Als u deze tarieven invoert, ontstaat er een bindende overeenkomst tussen CMAC Group en uw hotel en als zodanig moet u ervoor zorgen dat alleen een geautoriseerde persoon het formulier invult. Het hotel is verantwoordelijk voor de persoon die het formulier invult en als een onbevoegd persoon het formulier invult, heeft dit geen invloed op de CMAC Group of hun vermogen om deze tarieven af te dwingen.',
      ratesTermsConditionsText: 'Aankruisen om te bevestigen dat u onze ',
      ratesTermsConditionsLinkText: 'Algemene voorwaarden heeft gelezen',
      submittedHeading: 'Ingediend door',
      viewRatesTermsConditionsText: 'Klik hier om onze te bekijken',
      outstandingRateRequest: 'Openstaand tariefverzoek',
    },
    cmacRef: 'CMAC-kenm.',
    yourRef: 'Uw kenmerk',
    noNotificationEmailAddress: 'U heeft geen e-mailadres voor kennisgeving',
    leadMiles: 'Voorhoede-Mijlen',
    fareNet: 'Tarief (Netto)',
    leadMilesCost: 'Kostprijs voorhoede-mijlen (Netto)',
    totalCost: 'Totale kostprijs (Netto)',
    appliesOnDays: 'Geldt op dagen',
    noVehicleRates: 'Geen voertuigtarieven',
    markup: 'Opmaak',
    addedPrice: 'Toegevoegde Prijs',
    noHolidayRates: 'Geen vakantietarieven',
    standDownRates: 'Stand-down tarieven',
    noStandDownRates: 'Geen stand-down tarieven',
    stoodDownWithin: 'Binnen bleef staan',
    minutesOfDepartureDate: 'minuten van vertrekdatum',
    ofOriginalCostPrice: 'van de oorspronkelijke kostprijs',
    ofOriginalCostPriceWhicheverIsHigher:
      'van de oorspronkelijke kostprijs, afhankelijk van welke hoger is',
    waitingTimeRates: 'Wachttijdtarieven',
    noWaitingTimeRates: 'Geen wachttijdtarieven',
    minutesFree: 'minuten gratis',
    minutes: 'minuten',
  },
  common: {
    about: 'Over',
    accepted: 'Aanvaard',
    addComment: 'Voeg opm. toe',
    add: 'Toevoegen',
    addVehicle: 'Voertuig toevoegen',
    actions: 'Acties',
    addXtoY: 'Voeg {x} toe aan {y}',
    agreed: 'Overeengekomen',
    agreementType: 'Type overeenkomst',
    all: 'Alle',
    back: 'Terug',
    backToTop: 'Terug naar boven',
    cancel: 'Annuleren',
    close: 'Afsluiten',
    comment: 'Opmerking',
    comments: 'Opmerkingen',
    completed: 'Ingevuld',
    confirm: 'Bevestigen',
    continue: 'Doorgaan',
    cost: 'Kosten',
    create: 'Aanmaken',
    created: 'Aangemaakt',
    createdAt: 'Aangemaakt op',
    customer: 'Klant',
    date: 'Datum',
    dateRange: 'Datumbereik',
    delete: 'Verwijderen',
    departureDateRange: 'Vertrekdatum bereik',
    description: 'Omschrijving',
    details: 'Details',
    disputed: 'Betwist',
    distance: 'Afstand',
    documents: 'Documenten',
    download: 'Downloaden',
    edit: 'Bewerken',
    edited: 'Bewerkt',
    editVehicle: 'Bewerk voertuig',
    email: 'E-mail',
    enabled: 'Geactiveerd',
    error: 'Fout',
    example: 'Voorbeeld',
    expired: 'Verlopen',
    expires: 'Verloopt',
    failed: 'Mislukt',
    file: 'Bestand',
    filename: 'Bestandsnaam',
    filters: 'Filters',
    from: 'Van',
    full: 'Vol',
    half: 'Half',
    home: 'Startpagina',
    hide: 'Verbergen',
    id: 'Id',
    info: 'Info',
    important: 'Belangrijk',
    jobNo: 'Opdrachtnr.',
    loading: 'Bezig met laden',
    map: 'Kaart',
    messages: 'Berichten',
    miles: 'Kilometers',
    minutes: 'Minuten',
    mode: 'Modus',
    name: 'Naam',
    new: 'Nieuw',
    no: 'Nee',
    none: 'Geen',
    notes: 'Aantekeningen',
    null: 'Nul',
    ok: 'OK',
    open: 'Open',
    organisation: 'Organisatie',
    organisations: 'Organisaties',
    original: 'Origineel',
    others: 'Overige',
    password: 'Wachtwoord',
    pending: 'In behandeling',
    price: 'Prijs',
    previouslyUploaded: 'Eerder geupload',
    processed: 'Verwerkt',
    processing: 'Bezig met verwerken',
    protection: 'Bescherming',
    quantity: 'Aantal',
    raw: 'Raw',
    readonly: 'Alleen-lezen',
    reference: 'Kenmerk',
    refresh: 'Vernieuwen',
    reset: 'Reset',
    roles: 'Functies',
    save: 'Opslaan',
    search: 'Zoeken',
    settings: 'Instellingen',
    show: 'Tonen',
    signedBy: 'Ondertekend door',
    signedOn: 'Ondertekend op',
    sortBy: 'Sorteren op',
    status: 'Status',
    submit: 'Indienen',
    surcharge: 'Toeslag',
    test: 'Test',
    time: 'Tijd',
    title: 'Titel',
    to: 'Aan',
    total: 'Totaal',
    type: 'Type',
    unknown: 'Onbekend',
    update: 'Update',
    updated: 'Geupdated',
    updatedAt: 'Geupdated op',
    upload: 'Uploaden',
    uploaded: 'Geuploaded',
    user: 'Gebruiker',
    username: 'Gebruikersnaam',
    users: 'Gebruikers',
    website: 'Website',
    weight: 'Gewicht',
    yes: 'Ja',
    next: 'Volgende',
    hour: 'uur',
    mile: 'mijl',
    or: 'of',
  },
  currencies: {
    gbp: '£',
    eur: '€',
  },
  datetime: {
    monday: 'Maandag',
    tuesday: 'Dinsdag',
    wednesday: 'Woensdag',
    thursday: 'Donderdag',
    friday: 'Vrijdag',
    saturday: 'Zaterdag',
    sunday: 'Zondag',
    january: 'Januari',
    february: 'Februari',
    march: 'Maart',
    april: 'April',
    may: 'Mei',
    june: 'Juni',
    july: 'Juli',
    august: 'Augustus',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'December',
    today: 'Vandaag',
  },
  docs: {
    documentation: 'Documentatie',
    faq: 'Vaak gestelde vragen',
    frequentlyAskedQuestions: 'Vaak gestelde vragen',
    tutorials: 'Tutorials',
  },
  errors: {
    301: 'U bent niet geautoriseerd om toegang te krijgen tot deze bron.',
    400: 'Sorry, het verzoek was niet succesvol.',
    404: 'Sorry, we konden de bron die u zocht niet vinden.',
    500: 'De server kon het verzoek niet verwerken.',
    error: 'Fout',
    nError: '{n} fout',
    noResponseFromServer: 'Geen respons van server',
    noUrl: 'Geen url',
    sorrySomethingWentWrong: 'Sorry, er is iets fout gelopen',
    uploadFailed: 'Upload mislukt',
    uploadFailedWithError: 'Upload mislukt, met fout',
  },
  fields: {
    address: 'Adres',
    addresses: 'Adressen',
    addressLine1: 'Adresregel 1',
    addressLine2: 'Adresregel 2',
    addressLine3: 'Adresregel 3',
    addressLookup: 'Adres Opzoeken',
    city: 'Stad',
    country: 'Land',
    county: 'Provincie',
    description: 'Omschrijving',
    driverName: 'Naam van de bestuurder',
    driverPhone: 'Telefoonnummer van de bestuurder',
    driverRegistration: 'Registratie van de bestuurder',
    email: 'E-mail',
    emailAddress: 'E-mailadres',
    emailAddresses: 'E-mailadressen',
    home: 'Startpagina',
    mobile: 'Mobiele telefoon',
    name: 'Naam',
    number: 'Cijfer',
    phoneNumber: 'Telefoonnummer',
    postCode: 'Postcode',
    region: 'Regio',
    registrationNumber: 'Registratienummer',
    telephoneNumber: 'Telefoonnummer',
    telephoneNumbers: 'Telefoonnummers',
    title: 'Titel',
    titles: {
      dr: 'Dr',
      lady: 'Dame',
      lord: 'Heer',
      mr: 'Dhr',
      miss: 'Mej.',
      mrs: 'Mevr',
      ms: 'Mevr',
      mx: 'Mx',
      prof: 'Prof',
      sir: 'Sir',
    },
    town: 'Stad',
    work: 'Werk',
  },
  onboarding: {
    documents: {
      public_insurance: 'Certificaat openbare aansprakelijkheidsverzekering',
      liability: 'Bewijs van de werkgeversaansprakelijkheidsverzekering',
      motor_insurance: 'Autoverzekeringscertificaat',
      gdpr: 'AVG-beleid',
      small_operator_license: 'Bedrijfslicentie - kleine voertuigen',
      large_operator_license: 'Bedrijfslicentie - grote voertuigen',
      upload: 'Document uploaden',
      download: 'Document downloaden',
      file: 'Bestandsnaam',
      document: 'Documenttype',
      created: 'Aangemaakt op',
      expires: 'Verloopt op',
    },
    agreement: 'Ik heb de hierboven afgedrukte algemene voorwaarden gelezen en ga hiermee akkoord.',
    mustAgree: 'Om de app te gebruiken, moet u de algemene voorwaarden accepteren.',
    welcomeHeader: 'Welkom bij CMAC-leverancier inschrijving',
    country: 'Naam land',
    legalCompanyName: 'Wettelijke bedrijfsnaam',
    tradingName: 'Handelsnaam',
    companyRegistrationNumber: 'Bedrijfsregistratienummer',
    registeredAddress: 'Geregistreerd / juridisch kantoor',
    operatingAddress: 'Bedrijfsadres',
    currency: 'Uitloggen',
    logOut: 'Uitloggen',
    smallVehicle:
      'Klein voertuig (taxi, monovolume, aangepast rolstoeltoegankelijk voertuig, zwarte taxi)',
    largeVehicle: 'Groot voertuig (minibus, touringcar)',
    fleetSize: 'Grootte vloot',
    emailAddress: 'E-mailadres',
    mainContactNumber: 'Voer uw wachtwoord in...',
    primaryEmailAddress: 'Primair e-mailadres',
    successResult: 'Account is succesvol aangemaakt, controleer uw inbox',
    failResult:
      'We zijn op dit moment niet in staat om uw account aan te maken, account met de door u verstrekte informatie bestaat al, neem contact op met supplier.relations@cmacgroup.com voor meer informatie.',

    progressHeader: 'Bedankt voor het indienen van uw gegevens om een CMAC-leverancier te worden.',
    progressMainText:
      'Om verder te gaan met uw aanvragen, moet u ervoor zorgen dat alle delen van de onderstaande secties Financiële gegevens en On Boarding Documenten van het bedrijf zijn ingevuld.',
    progressSubText:
      'Zodra we deze informatie hebben ontvangen, kunnen we uw aanvraag naar de volgende fase verplaatsen.',
    progressBarText: 'On boarding voortgang',
    progressCompleted:
      'Bedankt voor het indienen van al uw documenten. Een van ons team zal uw indiening nu beoordelen en binnenkort contact met u opnemen om door te gaan naar de volgende stap.',
    rejected:
      'Helaas is uw aanvraag niet succesvol geweest. Neem contact op met ons team voor leveranciersrelaties voor meer informatie.',
  },
  plurals: {
    comments: 'Opmerking | Opmerkingen',
    journeys: 'Reis| Reizen',
    results: 'Resultaat | Resultaten',
    resultsFound: 'Resultaat gevonden | Resultaten gevonden',
    rows: 'Rij | Rijen',
    suppliers: 'Leverancier | Leveranciers',
  },
  ui: {
    anNewVersionOfTheApplicationIsAvailable:
      'Er is een nieuwe versie van de applicatie beschikbaar',
    appName: 'Leveranciersportal',
    approve: 'Goedkeuren',
    approveAllSelectedRows: 'Alle geselecteerde rijen goedkeuren',
    changeProfilePicture: 'Profielfoto wijzigen',
    chooseX: 'Kies {x}',
    clear: 'Wissen',
    clearFilters: 'Filters wissen',
    clickToCopyToClipboard: 'Klik om naar het klembord te kopiëren',
    clickToSortByX: 'Klikken om te sorteren op {x}',
    closeDrawer: 'Lade sluiten',
    currentlyUnavailable: 'Momenteel niet beschikbaar',
    dashboard: 'Dashboard',
    document: 'Document',
    dragYourXHereToUploadIt: 'Sleep uw {x} hier om deze te uploaden',
    export: 'Export',
    exportToCsv: 'Exporteren naar CSV',
    file: 'bestand',
    files: 'Bestanden',
    fileSizeTooBig: 'Bestandsgrootte te groot',
    footer: 'Voettekst',
    home: 'Startpagina',
    homePage: 'Startpagina',
    itemsPerPage: 'Items per pagina',
    navigation: 'Navigatie',
    noDetailsToDisplay: 'Geen details weer te geven',
    noFileSelected: 'Geen bestand geselecteerd',
    noResultsToShow: 'Geen resultaten weer te geven',
    noXProvided: 'Geen {x} voorzien',
    orClickToBrowse: 'Of klik om te bladeren',
    page: 'Pagina',
    pageSize: 'Paginaformaat',
    pleaseSeletFromTheFollowing: 'Selecteer uit de volgende',
    pleaseSelectAnX: 'Selecteer een {x}',
    pleaseSelectAX: 'Selecteer een {x}',
    pleaseSelectNXOnly: 'Selecteer alleen {n} {x}',
    profilePicture: 'Profielfoto',
    resultsPerPage: 'Resultaten per pagina',
    saveChanges: 'Wijzigingen opslaan',
    selectAll: 'Alle selecteren',
    selectX: 'Selecteer {x}',
    selectADifferentX: 'Selecteer een andere {x}',
    spreadsheet: 'Spreadsheet',
    theXHasBeenDeleted: 'De {x} werd verwijderd',
    thisWillPutXInDispute: 'Dit plaatst {x} in geschil',
    updateX: 'Update {x}',
    uploadBeingProcessed: 'Upload wordt verwerkt',
    uploadStatus: 'Upload status',
    uploading: 'Bezig met uploaden',
    uploadNewX: 'Upload nieuwe {x}',
    uploadX: 'Upload {x}',
    userProfile: 'Gebruikersprofiel',
    version: 'versie',
    xMissing: '{x} ontbreekt',
    policySignedX: 'Bedankt voor het ondertekenen van {x}',
  },
  validations: {
    canOnlyContainLettersAndNumbers: 'Kan alleen letters en cijfers bevatten',
    canOnlyContainLettersNumbersAndTheFollowingCharactersX:
      'Kan alleen letters, cijfers en de volgende tekens bevatten: {x}',
    canOnlyContainNumbers: 'Mag alleen cijfers bevatten',
    checkboxConfirm:
      'Vink het vakje aan om te bevestigen dat u deze algemene voorwaarden accepteert',
    maxNCharacters: 'Max {n} tekens',
    minNCharacters: 'Min {n} tekens',
    mustBeAnInteger: 'Moet een geheel getal zijn',
    mustBeAFloat: 'Moet een decimaal getal zijn',
    mustBeAValidEmailAddress: 'Moet een geldig e-mailadres zijn',
    mustBeAValidTelephoneNumber: 'Moet een geldig telefoonnummer zijn',
    mustBeAWholeNumber: 'Moet een geheel getal zijn',
    mustBeExactlyNCharacters: 'Moet precies {n} tekens zijn',
    mustBeGreaterThanN: 'Moet groter zijn dan {n}',
    mustBeGreaterThanOrEqualToN: 'Moet groter dan of gelijk zijn aan {n}',
    mustBeLessThanN: 'Moet kleiner zijn dan {n}',
    mustBeLessThanOrEqualToN: 'Moet kleiner dan of gelijk zijn aan {n}',
    mustBeRoundedToExactlyNDecimalPlaces: 'Moet afgerond zijn tot precies {n} decimale plaatsen',
    mustBeRoundedToNDecimalPlaces: 'Moet afgerond zijn tot {n} decimale plaatsen',
    mustHaveASingleDecimalPoint: 'Moet een enkele decimale punt hebben',
    requiresBooleanValue: 'Vereist boolean-waarde',
    thisDateMustBeInTheFuture: 'Deze datum moet in de toekomst zijn',
    thisDateMustBeInThePast: 'Deze datum moet in het verleden zijn',
    thisExceedsTheLeadMileageLimit: 'Dit overschrijdt de maximale extra kilometers',
    thisFieldIsRequired: 'Dit veld is verplicht',
    thisFieldMustBeAValidDate: 'Dit veld moet een geldige datum zijn',
    thisFieldMustContainAX: 'Dit veld moet een {x} bevatten',
    thisFieldMustContainAnX: 'Dit veld moet een {x} bevatten',
    mustBeUkBankSortCode: 'Sorteercode moet in het formaat 00-00-00 zijn',
    mustBeUkBankAccountNumber: 'Het Britse bankrekeningnummer moet 8 cijfers zijn',
    mustBeAtLeastOneChecked: 'Vink een of beide waarden aan',
  },
  invoiceUpload: {
    invoiceNo: 'Factuurnr.',
    reference: 'Kenmerk',
    uploadDate: 'Uploaddatum',
    invoiceDate: 'Factuurdatum',
    dueDate: 'Deadline',
    total: 'Totaal',
    currency: 'Munteenheid',
    status: 'Status',
    comments: 'Opmerkingen',
    file: 'Bestand',
    selectJourneys: 'Selecteer reizen',
    uploadInvoice: 'Factuur oploaden',
    confirm: 'Bevestigen',
    uploadingAs: 'U uploadt momenteel als',
    atLeastOneJourney: 'U moet ten minste één reis selecteren',
    errorUploadingFile: 'Er is een fout opgetreden bij het uploaden en analyseren van het bestand.',
    uploadInFormat: 'Upload uw factuur in PDF-formaat.',
    selectFile: 'Selecteer een bestand voor uploaden',
    uploadingAnalyzing: 'Uploaden en analyseren',
    invoiceReference: 'Factuurreferentie',
    invoice: 'Factuur',
    cmacReference: 'CMAC-referentie',
    yourReference: 'Uw referentie',
    journeysSelectionJourney: 'reis | reizen',
    journeySelectionSelected: 'geselecteerd',
    net: 'Netto',
    tax: 'Belasting',
    gross: 'Bruto',
    expenses: 'Onkosten',
    details: 'Details',
    journey: 'Reis',
    departureDate: 'Vertrekdatum',
    cmacRef: 'CMAC-kenmerk',
    yourRef: 'Uw kenmerk',
    invoiceAccepted: 'Factuur gevalideerd en aanvaard',
    invoiceDoesNotMatch: 'Controleer het formulier en verzend het opnieuw',
    stillUpload: 'U kunt deze factuur nog steeds uploaden, maar het vereist onderzoek.',
    paymentDelayed: 'Betaling wordt vertraagd totdat dit is voltooid.',
    totalNetDoesNotMatch:
      'Het Totaal Nettobedrag op de factuur komt niet voor alle geselecteerde reizen overeen met de totale nettoprijs.',
    expected: 'Verwacht',
    totalGross: 'Totaal bruto',
    totalNet: 'Totaal netto',
    totalTax: 'Totaal belasting',
    notFound: 'Niet gevonden',
    invoiced: 'Gefactureerd',
    underDispute: 'Betwist',
    notUnique: 'Een bestand met deze naam bestaat al. Kies een andere bestandsnaam.',
    invalidCharacters: 'De bestandsnaam bevat ongeldige tekens. Het volgende is niet toegestaan.',
    invalidFileExtension:
      'Dit type bestand is niet geldig. De volgende bestandstypen zijn toegestaan.',
    cancelled: 'Geannuleerd',
    journeyCost: 'Kostprijs reis (Netto)',
    leadMilesCost: 'Kostprijs voorhoede-mijlen (Netto)',
    waitingTimeCost: 'Kostprijs wachttijd (Netto)',
    expensesCost: 'Kostprijs onkosten (Netto)',
    greenFeeCost: 'Kostprijs groene heffing (Netto)',
    totalCost: 'Totale kostprijs (Netto)',
    downloadJourneyData: 'Klik hier om de gegevens voor de geselecteerde ritten te downloaden.',
    paid: 'Betaald',
    excludedJourneys: 'Dit proces sluit reizen uit waarover momenteel een geschil bestaat.',
    rejectedStatus: 'Afgewezen',
    createPdfInvoice: 'PDF-Factuur Maken',
    createPdf: {
      title: 'PDF-factuur maken voor geselecteerde reizen',
      invoiceNumber: 'Factuurnummer',
      invoiceDate: 'Factuurdatum',
      chooseAddress: 'Kies alstublieft een adres',
      registerDetails: {
        title: 'Registratiegegevens',
        heading: 'Om uw factuur te maken, hebben we wat extra informatie nodig.',
        financial: {
          heading: 'Volg de onderstaande stappen om uw financiële gegevens toe te voegen:',
          step1: 'Selecteer het hamburgermenu-icoon {x} rechtsboven op uw scherm',
          step2: "Selecteer dan 'Profiel'.",
          step3:
            "In de sectie 'Financiële Gegevens', klik op de bewerk-knop {x} en voer uw gegevens in.",
        },
        address: {
          heading: 'Volg de onderstaande stappen om het adres van uw bedrijf toe te voegen:',
          step1: 'Selecteer het hamburgermenu-icoon {x} rechtsboven op uw scherm.',
          step2: "Selecteer dan 'Profiel'.",
          step3: "In de sectie 'Adressen', klik op de plus-knop {x} en voer uw gegevens in.",
        },
      },
    },
    separateInvoicesRequired:
      'Reizen met voertuigen met meer dan 10 zitplaatsen moeten apart worden gefactureerd van reizen met voertuigen met minder dan 10 zitplaatsen.',
    invoiceNumberFieldNameLabel: 'Factuurnummer Veldnaam',
    totalNetAmountFieldNameLabel: 'Veldnaam totaal nettobedrag',
  },
  compliance: {
    introduction:
      'Hieronder vindt u een overzicht van de belangrijkste documenten die klaar moeten zijn en bij ons geregistreerd moeten worden. Als u vragen heeft over een van deze documenten, neem dan contact met ons op via 03333 207 100',
    noRulesFound: 'Geen compliance-regels gevonden',
  },
  contactItem: {
    generalManager: 'Algemeen directeur',
    reception24Hour: 'Receptie 24 uur',
    revenueSales: 'Omzet / omzet',
    twentyFourHourEmail: '24-uurs e-mail',
    notification: 'Kennisgeving',
    other: 'Ander',
  },
}
