export default {
  navbar: {
    userGuide: 'Ajuda & Guias',
  },
  settings: {
    emailTypes: {
      generalManager: 'Gerente Geral',
      reception: 'Recepção/Mesa de Entrada',
      sales: 'Receita/Vendas',
      email24: 'Email 24 Horas',
      notification: 'Notificação',
      other: 'Outro',
    },
  },
  auth: {
    emailAndPasswordDoNotMatchOurRecords:
      'O e-mail e a palavra-passe não correspondem aos nossos registos',
    forgottenYourPassword: 'Esqueceu-se da sua palavra-passe?',
    forgottenYourUsername: 'Esqueceu-se do seu nome de utilizador?',
    helpUsFindYourUsername:
      'Forneça os detalhes solicitados no formulário abaixo para nos ajudar a encontrar o seu nome de utilizador.',
    helpUsResetYourPassword:
      'Forneça-nos o seu nome de utilizador para nos ajudar a redefinir a sua palavra-passe.',
    login: 'Iniciar sessão',
    logIn: 'Iniciar sessão',
    logout: 'Terminar sessão',
    logOut: 'Terminar sessão',
    password: 'Palavra-passe',
    passwordReset: 'Redefinição da palavra-passe',
    pleaseEnterThePrimaryEmailAddress: 'Introduza o endereço de e-mail principal da sua conta.',
    pleaseEnterYourUsername: 'Introduza o seu nome de utilizador...',
    pleaseEnterYourPassword: 'Introduza a sua palavra-passe...',
    primaryEmailAddress: 'Endereço de e-mail principal',
    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'Infelizmente, o sistema não conseguiu autenticar o utilizador.  Normalmente, isto deve-se ao facto de a sua sessão ter expirado.  Inicie sessão novamente para continuar.',
    username: 'Nome de utilizador',
    usernameReminder: 'Lembrete de nome de utilizador',
    youAreBeingLoggedOut: 'A sessão está a ser terminada.',
    yourSessionHasExpired: 'A sua sessão expirou.  Por favor, inicie sessão novamente.',
  },
  availability: {
    availability: 'Disponibilidade',
    addAvailability: 'Adicionar disponibilidade',
    addingAvailability: 'Adicionando disponibilidade',
    accessible: 'Acessível',
    double: 'Duplo',
    family: 'Familiar',
    single: 'Individual',
    twin: 'Twin',
    room: 'Quarto',
    rooms: 'Quartos',
    numberAvailable: 'N.º disponível',
    noCmacRate: 'Sem preço CMAC',
    rate: 'Preço',
    useCmacAgreedRates: 'Utilizar os preços CMAC acordados',
    fullyBooked: 'Completamente reservado',
    helpText1: 'Para introduzir a disponibilidade, selecione uma data ou um intervalo de datas.',
    helpText2: 'Para selecionar uma data, clique na caixa correspondente a essa data.',
    helpText3: 'Para selecionar um intervalo, clique numa segunda caixa diferente.',
    helpText4:
      'As datas selecionadas aparecerão como itens vermelhos.  Para limpar uma data que tenha selecionado, clique na caixa correspondente a essa data.',
    helpText5:
      'Adicionar disponibilidade e introduza o número total de quartos disponíveis.  Se já tiver apresentado preços anteriormente, utilizaremos os preços CMAC acordados.  Caso contrário, introduza o preço do quarto apenas para a disponibilidade.',
    helpText6:
      'Pode adicionar várias entradas para a mesma data se tiver quartos disponíveis com preços diferentes.',
    publicHeader: 'CMAC Group - Pedido de disponibilidade - ',
    publicInstruction1:
      'Introduza o número de cada tipo de quarto que tem disponível e clique em Enviar',
    publicInstruction2:
      'Pode sempre utilizar esta ligação para voltar mais tarde e atualizar esta informação. O prazo de validade expira em',
    ratesIncludeTax: 'Por favor, certifique-se de que o preço INCLUI impostos / iva.',
    submittedBy: 'Enviado por ',
    updatedBy: 'atualizado por ',
    specificallyFor: 'Especificamente para ',
    parkFlyRoomOnly: 'Somente quarto dormir e voar',
    parkFly4Bundle: 'Quarto Sleep & Fly + 4 dias de estacionamento',
    parkFly8Bundle: 'Quarto Sleep & Fly + 8 dias de estacionamento',
    parkFly15Bundle: 'Sleep & Fly Room + 15 dias de estacionamento',
    parkFlyToCloseOut:
      'Para fechar uma data, desligue os itens relevantes. Para reativar a reserva, você precisará recarregar sua planilha de preços.',
  },
  cmac: {
    actionedBy: 'Acionado por',
    addAddress: 'Adicionar Endereço',
    addEmailAddress: 'Adicionar Endereço de E-mail',
    addTelephoneNumber: 'Adicionar Número de Telefone',
    addAComment: 'Adicionar um comentário',
    addComment: 'Adicionar comentário',
    addExpense: 'Adicionar Despesa',
    addingAComment:
      'Adicionar um comentário colocará esta viagem em disputa.  Utilize apenas para diferenças não indicadas na linha da viagem. Inclua todos os pormenores, por exemplo, a quilometragem e o motivo da consulta.',
    agreed: 'Acordado',
    allOpen: 'Todos em aberto',
    amend: 'Alterar',
    applies: 'Aplica-se a',
    apply: 'Aplicar',
    assignedOrganisation: 'Organização atribuída',
    availability: 'Disponibilidade',
    awaitingResponseFromSupplier: 'A aguardar resposta do fornecedor',
    billingAddress: 'Endereço de Faturação',
    bulkUpload: 'Carregamento em massa',
    bulkUploadHistory: 'Histórico de carregamento em massa',
    cancelled: 'Cancelado',
    closed: 'Fechado',
    cmac: 'CMAC',
    cmacReference: 'Referência CMAC',
    companyDetails: 'Dados da empresa',
    companyFinancialDetails: 'Dados financeiros',
    companyOnBoardingDocuments: 'Documentos de integração da empresa',
    companyNumber: 'Número da empresa',
    compliance: 'Conformidade',
    costConfirmation: 'Confirmação de custos',
    costVatBreakDown: 'Discriminação do custo do IVA',
    coverage: 'Cobertura',
    coverageAreas: 'Áreas de cobertura',
    currentlyImpersonating: 'Attualmente impersonando',
    customDates: 'Datas personalizadas',
    customerName: 'Nome do cliente',
    customerOrganisation: 'Organização do cliente',
    departureDate: 'Data de partida',
    depotAddress: 'Endereço do Depósito',
    dispute: 'Disputa',
    disputed: 'Disputada',
    disputeDetails: 'Detalhes da disputa',
    disputeEntry: 'Entrada da disputa',
    disputeEntries: 'Entradas da disputa',
    disputeId: 'ID da disputa',
    disputes: 'Disputas',
    disputesAwaitingAction: 'Disputas Aguardando Ação',
    documentType: 'Tipo de documento',
    driver: 'Condutor',
    driverDetails: 'Dados do condutor',
    editAddress: 'Editar Endereço',
    editEmailAddress: 'Editar Endereço de E-mail',
    editJourney: 'Editar viagem',
    editTelephoneNumber: 'Editar Número de Telefone',
    editOpenJourneys: 'Editar viagens abertas',
    endImpersonation: 'Termina la rappresentazione',
    endTime: 'Hora de finalização',
    expenseItem: {
      meetAndGreet: 'Encontro e saudação',
      parking: 'Estacionamento',
      toll: 'Pedágio',
      greenFee: 'Taxa ecológica',
      misc: 'Diversos',
      amount: 'Montante',
    },
    expenses: 'Despesas',
    expired: 'Expirado',
    expiryDate: 'Data de expiração',
    expiryDateRequired: 'Data de expiração exigida',
    extras: 'Extras',
    fare: 'Tarifa',
    farePriceCalculation: 'Cálculo do preço da tarifa',
    financialDetails: {
      accountName: 'Nome da Conta',
      bankAddress: 'Endereço do Banco',
      ibanNumber: 'Número IBAN',
      currency: 'Moeda',
    },
    fixedPrices: 'Preços fixos',
    greenFee: 'Tarifa ecológica',
    holidayRates: 'Preços de feriados',
    holidays: 'Feriados',
    holidayUplift: 'Elevação das férias',
    holidayUpliftDescription:
      'Estas datas personalizadas aplicam-se a TODAS as entradas da tabela de preços, a menos que sejam substituídas por uma data personalizada específica da Entrada',
    hotelMarkupRates: 'Preços comerciais de hotéis',
    hotelProfile: 'Perfil do hotel',
    hotelRatesTitle: 'Preços do hotel',
    ifYouNeedToChangeHowWeKnowYouInOurSystemPleaseCallTheSupplierRelationsTeamOn:
      'Se necessitar de alterar a sua apresentação no nosso sistema, contacte a Equipa de Relações com Fornecedores através do número',
    impersonating: 'Impersonificazione',
    inProgress: 'Em curso',
    invoiceId: 'ID da fatura',
    invoices: 'Faturas',
    job: 'Trabalho | Trabalhos',
    jobEntryId: 'ID de entrada do trabalho',
    jobTypes: 'Tipos de trabalho',
    journey: 'Viagem',
    journeys: 'Viagens',
    journeyChargeMethod: 'Método de cobrança da viagem',
    lastUpdated: 'Última atualização',
    leadMileage: 'Quilometragem sem passageiros',
    leadMileageCost: 'Custo da quilometragem sem passageiros',
    mileage: 'Quilometragem',
    mileageRates: 'Preços de quilometragem',
    minimumPrice: 'Preço mínimo',
    noCustomDates: 'Sem datas personalizadas',
    noOfPax: 'Nº de Pax',
    open: 'AbERTO',
    orAlternativelyEmail: 'Ou, em alternativa, enviar por e-mail',
    otherOrganisations: 'Outras organizações',
    passenger: 'Passageiro',
    parking: 'Estacionamento',
    parkingExpenses: 'Despesas de estacionamento',
    pax: 'Pax',
    percentageOfOriginalCostPrice: 'Percentagem do preço de custo original',
    permits: {
      currentHeading: 'AUTORIZAÇÕES ATUAIS',
      openHeading: 'ABERTO PARA APLICAÇÃO',
      title: 'Autorizações',
      claimed: 'Requeridas',
      status: 'Estado',
      expires: 'Expira',
      apply: 'Aplicar agora',
      outstandingAmendment: 'Existe um pedido de alteração pendente para esta licença.',
      noClaimedPermits: 'Não há licenças requeridas.',
      noOpenApplications: 'Não há pedidos em aberto.',
      download: 'Descarregar',
      print: 'Imprimir licença',
      amend: 'Alterar',
    },
    pickupDateTime: 'Data/Hora col.',
    pleaseAddAComment: 'Por favor, adicione um comentário',
    pleaseContactXForAssistance: 'Contactar {x} para obter assistência',
    pleaseSelectAXToImpersonate: 'Seleziona una {x} da impersonare',
    policiesAndTerms: 'Políticas e condições',
    pricingInformation: 'Informação sobre preços',
    processing: 'Processando',
    profile: 'Perfil',
    psvarStatus: 'Status do Psvar',
    rate: 'Preço',
    rateCards: 'Cartões de preços',
    referredToOperations: 'Referido às operações',
    referredToSupplierRelations: 'Referido a relações com fornecedor',
    referredToPricingManager: 'Referido ao gerente de preços',
    regions: 'Regiões',
    registration: 'Matrícula',
    responseReceivedFromSupplier: 'Resposta recebida do fornecedor',
    selectDocumentType: 'Selecionar tipo de documento',
    selectUserToImpersonate: "Seleziona l'utente da impersonare",
    standDownChargeMethod: 'Método de taxa de suspensão',
    standDownPrice: 'Preço de suspensão',
    startTime: 'Hora de início',
    statementId: 'ID da declaração',
    stoodDown: 'Suspendido',
    stoodDownWithinXMinutesOfDepartureDate:
      'Suspensão no prazo de {x} minutos após a data de partida',
    summary: 'Resumo',
    supplierName: 'Nome do fornecedor',
    supplierRef: 'Ref. fornecedor',
    termsAndConditions: 'Termos e condições',
    thankYouForLoggingInToTheSupplierPortal: 'Obrigado por se registar no portal de fornecedores',
    tierType: 'Tipo de nível',
    updateFailed: 'Atualização falhada',
    updateSuccessful: 'Atualização bem sucedida',
    userDetails: 'Dados do utilizador',
    hotelDetails: 'Dados do hotel',
    vatNumber: 'Número de IVA',
    vatTreatment: 'Tratamento de IVA',
    vehicle: 'Veículo',
    vehicleRates: 'Preços dos veículos',
    vias: 'Vias',
    viewAll: 'Ver tudo',
    waypoint: 'Ponto de passagem | Pontos de passagem',
    waitingTime: 'Tempo de espera',
    waitingTimeCost: 'Custo do tempo de espera',
    waitingTimeFree: 'Tempo de espera gratuito',
    waitingTimeMinutes: 'Tempo de espera minutos',
    waitingTimeRate: 'Preço do tempo de espera',
    withinXMinutes: 'Dentro de {x} minutos',
    vehicles: 'Veículos',
    vehicleRegistration: 'Registro de Veículo',
    vehicleType: 'Tipo de veículo',
    yourAccountIsCurrentlyInactive: 'A sua conta está atualmente inativa',
    yourReference: 'A sua referência',
    total: 'Total',
    invoiceuploads: 'Carregamento de faturas',
    hotelRates: {
      validBetween: 'Válido entre',
      addNewRate: 'Adicionar preços',
      addNewRateText: 'Existe um novo pedido de preço pendente para esta organização.',
      noRatesTextPrepend:
        'Não existem preços atuais para a sua organização. Por favor, contacte-nos em',
      noRatesTextAppend: 'para enviar os seus preços.',
      hasRatesText:
        'Abaixo estão os preços atuais que temos no nosso sistema.  Se precisar de fazer alguma alteração, contacte-nos em',
      profile: 'Perfil',
      profileStarRating: 'Classificação de estrelas',
      profileUnkown: 'Desconhecido',
      profileYes: 'Sim',
      profileNo: 'Não',
      profileYesFree: 'Sim Grátis',
      profileNumberOfRooms: 'Número de quartos',
      profileAccessibleRooms: 'Quartos acessíveis',
      profileFamilyRooms: 'Quartos familiares',
      profileHotelBus: 'Autocarro do hotel',
      profileParking: 'Estacionamento',
      profileSpa: 'Spa',
      profileSwimmingPool: 'Piscina',
      profileGym: 'Ginásio',
      profileRestaurant: 'Restaurante',
      commissionRate: 'Taxas de comissão',
      commissionRooms: 'Quartos',
      commissionFood: 'Alimentação',
      commissionDynamic: 'Dinâmica',
      editText: 'Cartão de preços do hotel',
      editProfileText: 'Editar dados do hotel',
      ratesValidDatesText: 'Os preços são aplicáveis entre estas datas',
      ratesValidFrom: 'Válido de',
      ratesValidTo: 'Válido até',
      rates: 'Preços',
      ratesType: 'Tipo',
      ratesRoomText: 'Preços por quarto para passageiros com atraso e tripulação de voo',
      ratesRoomType: 'Tipo de quarto',
      ratesMidweekText: 'Quarto apenas a meio da semana (Dom-Qui)',
      ratesDayLet: 'Aluguer durante o dia',
      ratesWeekendText: 'Quarto apenas ao fim de semana (Sex-Sáb)',
      ratesRoomTwinDouble: 'Twin / Duplo',
      ratesRoomSingle: 'Quarto individual',
      ratesRoomTriple: 'Triplo',
      ratesRoomFamily: 'Familiar',
      ratesWeekdayRate: 'Preço durante a semana',
      ratesWeekdayDayRate: 'Preço diário durante a semana',
      ratesWeekendRate: 'Preço ao fim de semana',
      ratesWeekendDayRate: 'Preço diário ao fim de semana',
      ratesFoodText: 'Comida e bebidas (incl. chá, café, cordial) Preço por pessoa',
      ratesContinentalBreakfast: 'Pequeno-almoço continental',
      ratesFullBreakfast: 'Pequeno-almoço completo',
      ratesLunch: 'Almoço',
      ratesDinner: 'Jantar',
      ratesAgreementText:
        'A introdução destes preços criará um acordo vinculativo entre o CMAC Group e o seu hotel e, como tal, deverá garantir que apenas um indivíduo autorizado preenche o formulário. O hotel será responsável pela pessoa que preenche o formulário e, se uma pessoa não autorizada preencher o formulário, tal não afetará o CMAC Group ou a sua capacidade de aplicar estes preços.',
      ratesTermsConditionsText: 'Por favor, assinale para confirmar que leu os nossos',
      ratesTermsConditionsLinkText: 'termos e condições',
      submittedHeading: 'Enviado por',
      viewRatesTermsConditionsText: 'Clique aqui para ver nosso',
      outstandingRateRequest: 'Pedido de taxa pendente',
    },
    cmacRef: 'Ref. CMAC',
    yourRef: 'Sua ref.',
    noNotificationEmailAddress: 'Você não tem endereço de e-mail de notificação',
    leadMiles: 'Milhas Percorridas',
    fareNet: 'Tarifa (Líquida)',
    leadMilesCost: 'Custo por Milhas Percorridas (Líquido)',
    totalCost: 'Custos Totais (Líquido)',
    appliesOnDays: 'Aplica-se em dias',
    noVehicleRates: 'Sem taxas de veículos',
    markup: 'Marcação',
    addedPrice: 'Preço Adicionado',
    noHolidayRates: 'Sem taxas de redução',
    standDownRates: 'Taxas de suspensão',
    noStandDownRates: 'No stand down rates',
    stoodDownWithin: 'Sem taxas de redução',
    minutesOfDepartureDate: 'minutos da data de partida',
    ofOriginalCostPrice: 'do preço de custo original',
    ofOriginalCostPriceWhicheverIsHigher: 'do preço de custo original, o que for maior',
    waitingTimeRates: 'Taxas de tempo de espera',
    noWaitingTimeRates: 'Sem taxas de tempo de espera',
    minutesFree: 'minutos grátis',
    minutes: 'minutos',
  },
  common: {
    about: 'Sobre',
    accepted: 'Aceite',
    add: 'Adicionar',
    addComment: 'Adic. coment.',
    addVehicle: 'Adicionar veículo',
    actions: 'Ações',
    addXtoY: 'Adicionar {x} a {y}',
    agreed: 'Acordado',
    agreementType: 'Tipo de acordo',
    all: 'Todos',
    back: 'Regressar',
    backToTop: 'Voltar ao topo',
    cancel: 'Cancelar',
    close: 'Fechar',
    comment: 'Comentário',
    comments: 'Comentários',
    completed: 'Concluído',
    confirm: 'Confirmar',
    continue: 'Continuar',
    cost: 'Custo',
    create: 'Criar',
    created: 'Criado',
    createdAt: 'Criado em',
    customer: 'Cliente',
    date: 'Data',
    dateRange: 'Intervalo de datas',
    delete: 'Eliminar',
    departureDateRange: 'Intervalo de datas de partida',
    description: 'Descrição',
    details: 'Detalhes',
    disputed: 'Disputada',
    distance: 'Distância',
    documents: 'Documentos',
    download: 'Descarregar',
    edit: 'Editar',
    edited: 'Editado',
    editVehicle: 'Editar veículo',
    email: 'E-mail',
    enabled: 'ativado',
    error: 'Erro',
    example: 'Exemplo',
    expired: 'Expirado',
    expires: 'Expira',
    failed: 'Falhado',
    file: 'Ficheiro',
    filename: 'Nome do ficheiro',
    filters: 'Filtros',
    from: 'Desde',
    full: 'Cheio',
    half: 'Meio',
    home: 'Início',
    hide: 'Esconder',
    id: 'Id',
    info: 'Informação',
    important: 'Importante',
    jobNo: 'Nº de trabalho',
    loading: 'Carregando',
    map: 'Mapa',
    messages: 'Mensagens',
    miles: 'Quilómetros',
    minutes: 'Minutos',
    mode: 'Modo',
    name: 'Nome',
    new: 'Novo',
    no: 'Não',
    none: 'Nenhum',
    notes: 'Notas',
    null: 'Nulo',
    ok: 'OK',
    open: 'Abrir',
    organisation: 'Organização',
    organisations: 'Organizações',
    original: 'Original',
    others: 'Outros',
    password: 'Palavra-passe',
    pending: 'Pendente',
    price: 'Preço',
    previouslyUploaded: 'Carregado anteriormente',
    processed: 'Processado',
    processing: 'Processando',
    protection: 'Proteção',
    quantity: 'Quantidade',
    raw: 'Cru',
    readonly: 'Só de leitura',
    reference: 'Referência',
    refresh: 'Atualizar',
    reset: 'Redefinir',
    roles: 'Funções',
    save: 'Guardar',
    search: 'Pesquisa',
    settings: 'Definições',
    show: 'Mostrar',
    signedBy: 'Assinado por',
    signedOn: 'Assinado em',
    sortBy: 'Ordenar por',
    status: 'Estado',
    submit: 'Enviar',
    surcharge: 'Sobretaxa',
    test: 'Teste',
    time: 'Hora',
    title: 'Título',
    to: 'Para',
    total: 'Total',
    type: 'Tipo',
    unknown: 'Desconhecido',
    update: 'Atualização',
    updated: 'Atualizado',
    updatedAt: 'Atualizado em',
    upload: 'Carregar',
    uploaded: 'Carregado',
    user: 'Utilizador',
    username: 'Nome de utilizador',
    users: 'Utilizadores',
    website: 'Website',
    weight: 'Peso',
    yes: 'Sim',
    next: 'Próximo',
    hour: 'hora',
    mile: 'milha',
    or: 'ou',
  },
  currencies: {
    gbp: '£',
    eur: '€',
  },
  datetime: {
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado',
    sunday: 'Domingo',
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro',
    today: 'Hoje',
  },
  docs: {
    documentation: 'Documentação',
    faq: 'FAQ',
    frequentlyAskedQuestions: 'Perguntas frequentes',
    tutorials: 'Tutoriais',
  },
  errors: {
    301: 'Não está autorizado a aceder a este recurso.',
    400: 'Lamentamos, mas o pedido não foi bem sucedido.',
    404: 'Lamentamos, mas não foi possível encontrar o recurso que procurava.',
    500: 'O servidor não conseguiu processar o pedido.',
    error: 'Erro',
    nError: '{n} erro',
    noResponseFromServer: 'Sem resposta do servidor',
    noUrl: 'Sem url',
    sorrySomethingWentWrong: 'Desculpe, ocorreu um erro',
    uploadFailed: 'A transferência falhou',
    uploadFailedWithError: 'O carregamento falhou, com erro',
  },
  fields: {
    address: 'Endereço',
    addresses: 'Endereços',
    addressLine1: 'Linha de endereço 1',
    addressLine2: 'Linha de endereço 2',
    addressLine3: 'Linha de endereço 3',
    addressLookup: 'Pesquisa de Endereço',
    city: 'Cidade',
    country: 'País',
    county: 'Município',
    description: 'Descrição',
    driverName: 'Nome do motorista',
    driverPhone: 'Número de telefone do motorista',
    driverRegistration: 'Registro do motorista',
    email: 'E-mail',
    emailAddress: 'Endereço de e-mail',
    emailAddresses: 'Endereços de e-mail',
    home: 'Início',
    mobile: 'Telemóvel',
    name: 'Nome',
    number: 'Número',
    phoneNumber: 'Número de telefone',
    postCode: 'Código postal',
    region: 'Região',
    registrationNumber: 'Número de matrícula',
    telephoneNumber: 'Número de telefone',
    telephoneNumbers: 'Números de telefone',
    title: 'Título',
    titles: {
      dr: 'Dr.',
      lady: 'Lady',
      lord: 'Lorde',
      mr: 'Sr.',
      miss: 'Sra.',
      mrs: 'Sra.',
      ms: 'Sra.',
      mx: 'Sra.',
      prof: 'Prof.',
      sir: 'Sir',
    },
    town: 'Localidade',
    work: 'Trabalho',
  },
  onboarding: {
    documents: {
      public_insurance: 'Certificado de seguro de responsabilidade civil',
      liability: 'Certificado de seguro de responsabilidade civil do empregador',
      motor_insurance: 'Certificado de Seguro Automóvel',
      gdpr: 'Política GDPR',
      small_operator_license: 'Carteira de Operador - veículos pequenos',
      large_operator_license: 'Licença de Operador - veículos de grande porte',
      upload: 'Carregar documento',
      download: 'Baixar documento',
      file: 'Nome do arquivo',
      document: 'Tipo de documento',
      created: 'Criado em',
      expires: 'Expira em',
    },
    agreement: 'Li e aceito os termos e condições impressos acima.',
    mustAgree: 'Você deve aceitar os termos e condições para usar o aplicativo.',
    welcomeHeader: 'Bem-vindo ao registo de fornecedor CMAC',
    country: 'Nome do país',
    legalCompanyName: 'Nome legal da empresa',
    tradingName: 'Nome comercial',
    companyRegistrationNumber: 'Número de registo da empresa',
    registeredAddress: 'Sede social/legal',
    operatingAddress: 'Endereço comercial',
    currency: 'Terminar sessão',
    logOut: 'Terminar sessão',
    smallVehicle: 'Veículo pequeno (táxi, monovolume, TPMR)',
    largeVehicle: 'Veículo grande (Minibus, Autocarro)',
    fleetSize: 'Dimensão da frota',
    emailAddress: 'Endereço de e-mail',
    mainContactNumber: 'Introduza a sua palavra-passe...',
    primaryEmailAddress: 'Endereço de e-mail principal',
    successResult: 'A conta foi criada com sucesso, por favor verifique a sua caixa de e-mail',
    failResult:
      'Não é possível criar a sua conta neste momento, a conta com as informações que forneceu já existe, por favor contacte supplier.relations@cmacgroup.com para obter mais informação.',

    progressHeader: 'Obrigado por enviar os seus dados para se tornar um fornecedor CMAC.',
    progressMainText:
      'Para prosseguir com a sua candidatura, certifique-se de que todas as partes das secções Detalhes financeiros e Documentos de integração da empresa abaixo estão preenchidas.',
    progressSubText:
      'Assim que recebermos esta informação, poderemos prosseguir com a sua candidatura para a fase seguinte.',
    progressBarText: 'Progresso da integração',
    progressCompleted:
      'Obrigado por enviar todos os seus documentos. Um membro da nossa equipe irá agora rever a sua submissão e entrar em contato em breve para avançar para a próxima etapa.',
    rejected:
      'Lamentavelmente, a sua candidatura não foi bem-sucedida. Por favor, entre em contato com a nossa equipe de relações com fornecedores para mais informações.',
  },
  plurals: {
    comments: 'Comentário | Comentários',
    journeys: 'Viagem| Viagens',
    results: 'Resultado | Resultados',
    resultsFound: 'Resultado encontrado | Resultados encontrados',
    rows: 'Linha | Linhas',
    suppliers: 'Fornecedor | Fornecedores',
  },
  ui: {
    anNewVersionOfTheApplicationIsAvailable: 'Está disponível uma nova versão da aplicação',
    appName: 'Portal do fornecedor',
    approve: 'Aprovar',
    approveAllSelectedRows: 'Aprovar todas as linhas selecionadas',
    changeProfilePicture: 'Alterar a imagem do perfil',
    chooseX: 'Escolher {x}',
    clear: 'Limpar',
    clearFilters: 'Limpar filtros',
    clickToCopyToClipboard: 'Clique para copiar para a área de transferência',
    clickToSortByX: 'Clicar para ordenar por {x}',
    closeDrawer: 'Fechar gaveta',
    currentlyUnavailable: 'Atualmente indisponível',
    dashboard: 'Painel de controlo',
    document: 'Documento',
    dragYourXHereToUploadIt: 'Arraste o seu {x} para aqui para o carregar',
    export: 'Exportar',
    exportToCsv: 'Exportar para CSV',
    file: 'ficheiro',
    files: 'Arquivos',
    fileSizeTooBig: 'Ficheiro com tamanho demasiado grande',
    footer: 'Rodapé',
    home: 'Início',
    homePage: 'Página Inicial',
    itemsPerPage: 'Itens por página',
    navigation: 'Navegação',
    noDetailsToDisplay: 'Não há dados para apresentar',
    noFileSelected: 'Nenhum ficheiro selecionado',
    noResultsToShow: 'Não há resultados para mostrar',
    noXProvided: 'Nenhum {x} fornecido',
    orClickToBrowse: 'Ou clique para navegar',
    page: 'Página',
    pageSize: 'Tamanho da página',
    pleaseSeletFromTheFollowing: 'Selecione uma das seguintes opções',
    pleaseSelectAnX: 'Selecione um {x}',
    pleaseSelectAX: 'Selecione um {x}',
    pleaseSelectNXOnly: 'Selecione {n} {x} apenas',
    profilePicture: 'Imagem de perfil',
    resultsPerPage: 'Resultados por página',
    saveChanges: 'Guardar alterações',
    selectAll: 'Selecionar tudo',
    selectX: 'Selecionar {x}',
    selectADifferentX: 'Selecionar um {x} diferente',
    spreadsheet: 'Folha de cálculo',
    theXHasBeenDeleted: 'O {x} foi eliminado',
    thisWillPutXInDispute: 'Isto colocará {x} em disputa',
    updateX: 'Atualização {x}',
    uploadBeingProcessed: 'O carregamento está a ser processado',
    uploadStatus: 'Estado do carregamento',
    uploading: 'A carregar',
    uploadNewX: 'Carregar novo {x}',
    uploadX: 'Carregar {x}',
    userProfile: 'Perfil do utilizador',
    version: 'versão',
    xMissing: '{x} em falta',
    policySignedX: 'Obrigado por assinar {x}',
  },
  validations: {
    canOnlyContainLettersAndNumbers: 'Só pode conter letras e números',
    canOnlyContainLettersNumbersAndTheFollowingCharactersX:
      'Só pode conter letras, números e os seguintes caracteres: {x}',
    canOnlyContainNumbers: 'Só pode conter números',
    checkboxConfirm:
      'Por favor, selecione a caixa para confirmar que aceita estes termos e condições',
    maxNCharacters: 'Máximo {n} caracteres',
    minNCharacters: 'Mínimo {n} caracteres',
    mustBeAnInteger: 'Deve ser um número inteiro',
    mustBeAFloat: 'Deve ser um número decimal',
    mustBeAValidEmailAddress: 'Deve ser um endereço de e-mail válido',
    mustBeAValidTelephoneNumber: 'Deve ser um número de telefone válido',
    mustBeAWholeNumber: 'Deve ser um número inteiro',
    mustBeExactlyNCharacters: 'Deve ter exatamente {n} caracteres',
    mustBeGreaterThanN: 'Deve ser maior que {n}',
    mustBeGreaterThanOrEqualToN: 'Deve ser maior ou igual a {n}',
    mustBeLessThanN: 'Deve ser menor a {n}',
    mustBeLessThanOrEqualToN: 'Deve ser menor ou igual a {n}',
    mustBeRoundedToExactlyNDecimalPlaces: 'Deve ser arredondado exatamente para {n} casas decimais',
    mustBeRoundedToNDecimalPlaces: 'Deve ser arredondado para {n} casas decimais',
    mustHaveASingleDecimalPoint: 'Deve ter um único ponto decimal',
    requiresBooleanValue: 'Requer um valor booleano',
    thisDateMustBeInTheFuture: 'Esta data tem de estar no futuro',
    thisDateMustBeInThePast: 'Esta data tem de estar no passado',
    thisExceedsTheLeadMileageLimit: 'Isto excede o limite de quilometragem sem passageiros',
    thisFieldIsRequired: 'Este campo é obrigatório',
    thisFieldMustBeAValidDate: 'Este campo deve ser uma data válida',
    thisFieldMustContainAX: 'Este campo deve conter um {x}',
    thisFieldMustContainAnX: 'Este campo deve conter um {x}',
    mustBeUkBankSortCode: 'O código de classificação bancária deve estar no formato 00-00-00',
    mustBeUkBankAccountNumber: 'O número da conta bancária do Reino Unido deve ter 8 dígitos',
    mustBeAtLeastOneChecked: 'Marque um ou ambos os valores',
  },
  invoiceUpload: {
    invoiceNo: 'Fatura n.º',
    reference: 'Referência',
    uploadDate: 'Data de carregamento',
    invoiceDate: 'Data da fatura',
    dueDate: 'Data de vencimento',
    total: 'Total',
    currency: 'Moeda',
    status: 'Estado',
    comments: 'Comentários',
    file: 'Ficheiro',
    selectJourneys: 'Selecionar viagens',
    uploadInvoice: 'Carregar fatura',
    confirm: 'Confirmar',
    uploadingAs: 'Está atualmente a carregar como',
    atLeastOneJourney: 'É necessário selecionar pelo menos uma viagem',
    errorUploadingFile: 'Ocorreu um erro ao carregar e analisar o ficheiro.',
    uploadInFormat: 'Por favor, carregue a sua fatura em formato PDF.',
    selectFile: 'Por favor, selecione um ficheiro para carregar',
    uploadingAnalyzing: 'Carregamento e análise',
    invoiceReference: 'Referência da fatura',
    invoice: 'Fatura',
    cmacReference: 'Referência CMAC',
    yourReference: 'A sua referência',
    journeysSelectionJourney: 'viagem | viagens',
    journeySelectionSelected: 'selecionado',
    net: 'Líquido',
    tax: 'Imposto',
    gross: 'Bruto',
    expenses: 'Despesas',
    details: 'Detalhes',
    journey: 'Viagem',
    departureDate: 'Data de partida',
    cmacRef: 'Ref. CMAC',
    yourRef: 'Sua ref.',
    invoiceAccepted: 'Fatura validada e aceite',
    invoiceDoesNotMatch: 'Revise e reenvie',
    stillUpload: 'Pode continuar a carregar esta fatura, mas será necessário investigá-la.',
    paymentDelayed: 'O pagamento será adiado até que esta investigação esteja concluída.',
    totalNetDoesNotMatch:
      'O valor líquido total na fatura não corresponde ao preço líquido total de todas as viagens selecionadas.',
    expected: 'Esperado',
    totalGross: 'Total bruto',
    totalNet: 'Total líquido',
    totalTax: 'Total de impostos',
    notFound: 'Não encontrado',
    invoiced: 'Faturado',
    underDispute: 'Em disputa',
    notUnique: 'Já existe um arquivo com este nome. Escolha um nome de arquivo diferente.',
    invalidCharacters: 'O nome do arquivo contém caracteres inválidos. O seguinte não é permitido.',
    invalidFileExtension:
      'Este tipo de arquivo não é válido. Os seguintes tipos de arquivo são permitidos.',
    cancelled: 'Cancelado',
    journeyCost: 'Custo da Viagem (Líquido)',
    leadMilesCost: 'Custo por Milhas Percorridas (Líquido)',
    waitingTimeCost: 'Custos de Tempo de Espera (Líquido)',
    expensesCost: 'Custos de Despesas (Líquido)',
    greenFeeCost: 'Custos da Taxa Verde (Líquido)',
    totalCost: 'Custos Totais (Líquido)',
    downloadJourneyData: 'Clique aqui para baixar os dados para as viagens selecionadas.',
    paid: 'Pago',
    excludedJourneys: 'Este processo exclui viagens que estão atualmente em disputa.',
    rejectedStatus: 'Rejeitada',
    createPdfInvoice: 'Criar Fatura em PDF',
    createPdf: {
      title: 'Criar Fatura em PDF para as viagens selecionadas',
      invoiceNumber: 'Número da Fatura',
      invoiceDate: 'Data da Fatura',
      chooseAddress: 'Por favor, escolha um endereço',
      registerDetails: {
        title: 'Detalhes do Registro',
        heading: 'Para criar a sua fatura, precisamos de algumas informações adicionais.',
        financial: {
          heading: 'Siga os passos abaixo para adicionar seus detalhes financeiros:',
          step1:
            'Selecione o ícone do menu hambúrguer {x} localizado no canto superior direito da sua tela',
          step2: "Em seguida, selecione 'Perfil'.",
          step3:
            "Na seção 'Detalhes Financeiros', clique no botão de edição {x} e insira seus dados.",
        },
        address: {
          heading: 'Siga os passos abaixo para adicionar o endereço da sua empresa:',
          step1:
            'Selecione o ícone do menu hambúrguer {x} localizado no canto superior direito da sua tela.',
          step2: "Em seguida, selecione 'Perfil'.",
          step3: "Na seção 'Endereços', clique no botão de adição {x} e insira seus dados.",
        },
      },
    },
    separateInvoicesRequired:
      'Viagens com veículos com mais de 10 assentos precisam ser faturadas separadamente das viagens com veículos com menos de 10 assentos.',
    invoiceNumberFieldNameLabel: 'Nome do campo do número da fatura',
    totalNetAmountFieldNameLabel: 'Nome do campo Valor líquido total',
  },
  compliance: {
    introduction:
      'A seguir, há um resumo dos principais documentos que devem estar prontos e registrados conosco. Se você tiver alguma dúvida sobre qualquer um desses documentos, entre em contato conosco pelo número 03333 207 100',
    noRulesFound: 'Nenhuma regra de conformidade encontrada',
  },
  contactItem: {
    generalManager: 'Director Geral',
    reception24Hour: 'Recepção 24 horas',
    revenueSales: 'Receita / Vendas',
    twentyFourHourEmail: 'E-mail 24 horas',
    notification: 'Notificação',
    other: 'Outro',
  },
}
