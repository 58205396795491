export default {
  navbar: {
    userGuide: 'Pomoc & Przewodniki',
  },
  settings: {
    emailTypes: {
      generalManager: 'Dyrektor Generalny',
      reception: 'Recepcja/Front Desk',
      sales: 'Dochód/Sprzedaż',
      email24: 'E-mail 24-godzinny',
      notification: 'Powiadomienie',
      other: 'Inne',
    },
  },
  auth: {
    emailAndPasswordDoNotMatchOurRecords:
      'Adres e-mail oraz hasło nie pokrywają się z naszymi danymi',
    forgottenYourPassword: 'Zapomniałeś hasła?',
    forgottenYourUsername: 'Zapomniałeś nazwy użytkownika?',
    helpUsFindYourUsername:
      'Prosimy o podanie informacji w poniższym formularzu, żebyśmy mogli znaleźć Twoją nazwę użytkownika.',
    helpUsResetYourPassword:
      'Prosimy o podanie swojej nazwy użytkownika, żebyśmy mogli zresetować Twoje hasło.',
    login: 'Logowanie',
    logIn: 'Zaloguj się',
    logout: 'Wylogowanie',
    logOut: 'Wyloguj się',
    password: 'Hasło',
    passwordReset: 'Resetowanie hasła',
    pleaseEnterThePrimaryEmailAddress: 'Prosimy o podanie głównego adresu e-mail dla tego konta.',
    pleaseEnterYourUsername: 'Prosimy o podanie nazwy użytkownika...',
    pleaseEnterYourPassword: 'Prosimy o podanie swojego hasła...',
    primaryEmailAddress: 'Główny adres e-mail',
    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'Niestety system nie zdołał przeprowadzić uwierzytelnienia Twoich danych. Zazwyczaj jest to spowodowane wygaśnięciem sesji. Prosimy o ponowne zalogowanie się, aby móc kontynuować.',
    username: 'Nazwa użytkownika',
    usernameReminder: 'Przypomnienie nazwy użytkownika',
    youAreBeingLoggedOut: 'Trwa wylogowanie.',
    yourSessionHasExpired: 'Twoja sesja wygasła. Prosimy o ponowne zalogowanie się.',
  },
  availability: {
    availability: 'Dostępność',
    addAvailability: 'Dodaj dostępność',
    addingAvailability: 'Trwa dodawanie dostępności',
    accessible: 'Dostępny',
    double: 'Dwuosobowy (jedno łóżko)',
    family: 'Rodzinny',
    single: 'Jednoosobowy',
    twin: 'Dwuosobowy (jedno łóżko)',
    room: 'Pokój',
    rooms: 'Pokoje',
    numberAvailable: 'Liczba wolnych',
    noCmacRate: 'Brak stawki CMAC',
    rate: 'Stawka',
    useCmacAgreedRates: 'Użyj uzgodnionych stawek CMAC',
    fullyBooked: 'Brak wolnych miejsc',
    helpText1: 'Aby dodać dostępność, wybierz datę lub zakres dat.',
    helpText2: 'Aby wybrać datę, kliknij w pole z tą datą.',
    helpText3: 'Aby wybrać zakres dat, kliknij w drugie, inne pole.',
    helpText4:
      'Zaznaczone daty pojawią się na czerwono. Aby wyczyścić zaznaczoną datę, kliknij w pole z tą datą.',
    helpText5:
      'Kliknij w przycisk Dodaj dostępność i podaj łączną liczbę dostępnych pokojów. Jeśli podano wcześniej stawki, użyjemy uzgodnionych stawek CMAC.  W przeciwnym razie w polu z dostępnością podaj tylko stawkę za pokój.',
    helpText6:
      'Możesz dodać kilka wpisów dla tej samej daty, jeśli posiadasz dostępne pokoje w różnych stawkach.',
    publicHeader: 'CMAC Group - Zapytanie o dostępność - ',
    publicInstruction1: 'Podaj liczbę dla każdego rodzaju dostępnych pokojów i kliknij Prześlij',
    publicInstruction2:
      'Zawsze możesz użyć tego łącza, aby później zaktualizować te informacje. Link wygaśnie o',
    ratesIncludeTax: 'Prosimy pamiętać, aby stawka ZAWIERAŁA już podatek / VAT.',
    submittedBy: 'Przesłane przez ',
    updatedBy: 'aktualizowany przez ',
    specificallyFor: 'Specjalnie dla ',
    parkFlyRoomOnly: 'Tylko pokój typu Sleep & Fly',
    parkFly4Bundle: 'Pokój Sleep & Fly + parking na 4 dni',
    parkFly8Bundle: 'Pokój Sleep & Fly + parking na 8 dni',
    parkFly15Bundle: 'Pokój Sleep & Fly + 15-dniowy parking',
    parkFlyToCloseOut:
      'Aby zamknąć datę, wyłącz odpowiednie pozycje. Aby ponownie włączyć rezerwację, musisz ponownie przesłać arkusz kalkulacyjny z cenami.',
  },
  cmac: {
    actionedBy: 'Zatwierdził(a)',
    addAddress: 'Dodaj Adres',
    addEmailAddress: 'Dodaj Adres E-mail',
    addTelephoneNumber: 'Dodaj Numer Telefonu',
    addAComment: 'Dodaj komentarz',
    addComment: 'Dodaj komentarz',
    addExpense: 'Dodaj Wydatek',
    addingAComment:
      'Dodanie komentarza otworzy dyskusję dla tej podróży. Prosimy używać tej funkcji wyłącznie w przypadku rozbieżności niewykazanych w opisie podróży. Prosimy o podanie wszystkich informacji, np. liczby kilometrów lub powodu zapytania.',
    agreed: 'Ustalone',
    allOpen: 'Wszystkie otwarte',
    amend: 'Popraw',
    applies: 'Dotyczy',
    apply: 'Dotyczą',
    assignedOrganisation: 'Przydzielona organizacja',
    availability: 'Dostępność',
    awaitingResponseFromSupplier: 'Oczekuję na odpowiedź dostawcy',
    billingAddress: 'Adres Rozliczeniowy',
    bulkUpload: 'Prześlij pakiet',
    bulkUploadHistory: 'Historia przesłanych pakietów',
    cancelled: 'Anulowane',
    closed: 'Zamknięte',
    cmac: 'CMAC',
    cmacReference: 'Nr ref. CMAC',
    companyDetails: 'Dane firmy',
    companyFinancialDetails: 'Informacje finansowe',
    companyOnBoardingDocuments: 'Dokumenty rekrutacyjne firmy',
    companyNumber: 'Numer firmy',
    compliance: 'Zgodność z przepisami',
    costConfirmation: 'Potwierdzenie kosztów',
    costVatBreakDown: 'Wyszczególnienie kosztów VAT',
    coverage: 'Zasięg',
    coverageAreas: 'Obszary zasięgu',
    currentlyImpersonating: 'Obecnie podaje się za kogoś',
    customDates: 'Daty niestandardowe',
    customerName: 'Nazwa klienta',
    customerOrganisation: 'Organizacja klienta',
    departureDate: 'Data wyjazdu',
    depotAddress: 'Adres Depozytu',
    dispute: 'Dyskusja',
    disputed: 'Dyskutowane',
    disputeDetails: 'Szczegóły dyskusji',
    disputeEntry: 'Wpis do dyskusji',
    disputeEntries: 'Wpisy do dyskusji',
    disputeId: 'Nr ID dyskusji',
    disputes: 'Dyskusje',
    disputesAwaitingAction: 'Spory Oczekujące na Działanie',
    documentType: 'Rodzaj dokumentu',
    driver: 'Kierowca',
    driverDetails: 'Dane kierowcy',
    editAddress: 'Edytuj Adres',
    editEmailAddress: 'Edytuj Adres E-mail',
    editJourney: 'Edytuj podróż',
    editTelephoneNumber: 'Edytuj Numer Telefonu',
    editOpenJourneys: 'Edytuj otwarte podróże',
    endImpersonation: 'Zakończ podawanie się za kogoś',
    endTime: 'Godzina zakończenia',
    expenseItem: {
      meetAndGreet: 'Spotkanie i powitanie',
      parking: 'Parking',
      toll: 'Opłata drogowa',
      greenFee: 'Opłata ekologiczna',
      misc: 'Różne',
      amount: 'Kwota',
    },
    expenses: 'Wydatki',
    expired: 'Wygasłe',
    expiryDate: 'Data wygaśnięcia',
    expiryDateRequired: 'Wymagana data wygaśnięcia',
    extras: 'Dodatki',
    fare: 'Opłata',
    financialDetails: {
      accountName: 'Nazwa Konta',
      bankAddress: 'Adres Banku',
      ibanNumber: 'Numer IBAN',
      currency: 'Waluta',
    },
    farePriceCalculation: 'Kalkulacja opłaty',
    fixedPrices: 'Ustalone ceny',
    greenFee: 'Opłata ekologiczna',
    holidayRates: 'Stawki świąteczne',
    holidays: 'Święta',
    holidayUplift: 'Wakacyjny uplift',
    holidayUpliftDescription:
      'Te niestandardowe daty mają zastosowanie do WSZYSTKICH wpisów karty cen, chyba że zostaną zastąpione przez niestandardową datę specyficzną dla wpisu',
    hotelMarkupRates: 'Stawki marży hotelu',
    hotelProfile: 'Profil hotelu',
    hotelRatesTitle: 'Stawki hotelowe',
    ifYouNeedToChangeHowWeKnowYouInOurSystemPleaseCallTheSupplierRelationsTeamOn:
      'Jeśli chcesz zmienić swoje dane w naszym systemie, prosimy o kontakt telefoniczny z zespołem ds. dostawców pod numerem',
    impersonating: 'Trwa podawanie się za kogoś',
    inProgress: 'W trakcie',
    invoiceId: 'Nr ID faktury',
    invoices: 'Faktury',
    job: 'Zlecenie | Zlecenia',
    jobEntryId: 'Nr ID zlecenia',
    jobTypes: 'Rodzaje zleceń',
    journey: 'Podróż',
    journeys: 'Podróże',
    journeyChargeMethod: 'Metoda naliczania opłaty za podróż',
    lastUpdated: 'Ostatnia aktualizacja',
    leadMileage: 'Kilometrówka bez pasażera',
    leadMileageCost: 'Koszt kilometrówki bez pasażera',
    mileage: 'Kilometrówka',
    mileageRates: 'Stawki kilometrówki',
    minimumPrice: 'Cena minimalna',
    noCustomDates: 'Brak niestandardowych dat',
    noOfPax: 'Liczba pas.',
    open: 'Otwarte',
    orAlternativelyEmail: 'Albo adres e-mail',
    otherOrganisations: 'Inne organizacje',
    passenger: 'Pasażer',
    parking: 'Parking',
    parkingExpenses: 'Koszty parkowania',
    pax: 'Pasażerowie',
    percentageOfOriginalCostPrice: 'Procent kosztu początkowego',
    permits: {
      currentHeading: 'AKTUALNE ZEZWOLENIA',
      openHeading: 'OTWARTE APLIKACJE',
      title: 'Zezwolenia',
      claimed: 'Złożone',
      status: 'Status',
      expires: 'Mija termin',
      apply: 'Aplikuj',
      outstandingAmendment: 'Została zaległa prośba o poprawkę dla tego zezwolenia.',
      noClaimedPermits: 'Brak złożonych zezwoleń.',
      noOpenApplications: 'Brak otwartych wniosków.',
      download: 'Pobierz',
      print: 'Drukuj zezwolenie',
      amend: 'Popraw',
    },
    pickupDateTime: 'Data/czas odb.',
    pleaseAddAComment: 'Prosimy o dodanie komentarza',
    pleaseContactXForAssistance: 'Prosimy o kontakt z {x} w celu uzyskania pomocy',
    pleaseSelectAXToImpersonate: 'Prosimy wybrać {x} w celu podania się za kogoś',
    policiesAndTerms: 'Polityka firmy i regulamin',
    pricingInformation: 'Informacje dotyczące cen',
    processing: 'Trwa przetwarzanie',
    profile: 'Profil',
    psvarStatus: 'Status Psvar',
    rate: 'Stawka',
    rateCards: 'Cennik',
    referredToOperations: 'Skierowany do działu operacyjnego',
    referredToSupplierRelations: 'Skierowany do działu relacji z dostawcami',
    referredToPricingManager: 'Skierowany do kierownika ds. cen',
    regions: 'Regiony',
    registration: 'Rejestracja',
    responseReceivedFromSupplier: 'Odpowiedź dostawcy',
    selectDocumentType: 'Wybierz rodzaj dokumentu',
    selectUserToImpersonate: 'Wybierz użytkownika do podania się za inną osobę',
    standDownChargeMethod: 'Metoda naliczania opłaty przestojowej',
    standDownPrice: 'Cena za przestój',
    startTime: 'Czas rozpoczęcia',
    statementId: 'Nr ID zeznania',
    stoodDown: 'Przestój',
    stoodDownWithinXMinutesOfDepartureDate: 'Przestój w ciągu {x} minut od daty wyjazdu',
    summary: 'Podsumowanie',
    supplierName: 'Nazwa dostawcy',
    supplierRef: 'Ref. dostawcy',
    termsAndConditions: 'Warunki umowy',
    thankYouForLoggingInToTheSupplierPortal:
      'Dziękujemy za zalogowanie się na portal dla dostawców',
    tierType: 'Szczebel',
    updateFailed: 'Aktualizacja zakończona niepowodzeniem',
    updateSuccessful: 'Aktualizacja zakończona powodzeniem',
    userDetails: 'Dane użytkownika',
    hotelDetails: 'Dane hotelu',
    vatNumber: 'Numer VAT',
    vatTreatment: 'Traktowanie VAT',
    vehicle: 'Pojazd',
    vehicleRegistration: 'Rejestracja Pojazdu',
    vehicleRates: 'Stawki za pojazd',
    vias: 'Drogi',
    viewAll: 'Zobacz wszystkie',
    waypoint: 'Punkt na trasie | Punkty na trasie',
    waitingTime: 'Czas oczekiwania',
    waitingTimeCost: 'Koszt czasu oczekiwania',
    waitingTimeFree: 'Darmowy czas oczekiwania',
    waitingTimeMinutes: 'Liczba minut oczekiwania',
    waitingTimeRate: 'Stawka za czas oczekiwania',
    withinXMinutes: 'W ciągu {x} minut',
    vehicles: 'Pojazdy',
    vehicleType: 'Typ pojazdu',
    yourAccountIsCurrentlyInactive: 'Twoje konto jest aktualnie nieaktywne',
    yourReference: 'Twój nr referencyjny',
    total: 'Łącznie',
    invoiceuploads: 'Przesłane faktury',
    hotelRates: {
      validBetween: 'Obowiązuje między',
      addNewRate: 'Dodaj stawki',
      addNewRateText: 'Dla tej organizacji jest nowe zaległe żądanie dotyczące stawki.',
      noRatesTextPrepend: 'Brak aktualnych stawek dla Twojej organizacji. Prosimy o kontakt:',
      noRatesTextAppend: 'w celu przedstawienia stawek.',
      hasRatesText:
        'Poniżej znajdują się aktualne stawki, które posiadamy w naszym systemie.  Jeśli chcą Państwo wprowadzić jakieś zmiany, należy skontaktować się z nami pod adresem ',
      profile: 'Profil',
      profileStarRating: 'Ocena w skali gwiazdkowej',
      profileUnkown: 'Nieznany',
      profileYes: 'Tak',
      profileNo: 'Nie',
      profileYesFree: 'Tak za darmo',
      profileNumberOfRooms: 'Liczba pokoi',
      profileAccessibleRooms: 'Dostępne pokoje',
      profileFamilyRooms: 'Pokoje rodzinne',
      profileHotelBus: 'Autobus hotelowy',
      profileParking: 'Parking',
      profileSpa: 'Spa',
      profileSwimmingPool: 'Basen',
      profileGym: 'Siłownia',
      profileRestaurant: 'Restauracja',
      commissionRate: 'Stawki prowizji',
      commissionRooms: 'Pokoje',
      commissionFood: 'Żywność',
      commissionDynamic: 'Dynamiczna',
      editText: 'Cennik hotelowy',
      editProfileText: 'Edytuj dane hotelu',
      ratesValidDatesText: 'Stawki obowiązują między tymi datami',
      ratesValidFrom: 'Ważne od',
      ratesValidTo: 'Ważne do',
      rates: 'Stawki',
      ratesType: 'Rodzaj',
      ratesRoomText: 'Stawki za pokój dla spóźnionych pasażerów i załogi samolotu',
      ratesRoomType: 'Typ pokoju',
      ratesMidweekText: 'Pokój tylko w środku tygodnia (niedz.-czw.)',
      ratesDayLet: 'Wynajem na dzień',
      ratesWeekendText: 'Pokój tylko na weekendy (piątek-sobota)',
      ratesRoomTwinDouble: 'Dwuosobowy (jedno łóżko)',
      ratesRoomSingle: 'Pokój jednoosobowy',
      ratesRoomTriple: 'Pokój trzyosobowy',
      ratesRoomFamily: 'Rodzinny',
      ratesWeekdayRate: 'Stawka w dni powszednie',
      ratesWeekdayDayRate: 'Stawka dzienna w dni powszednie',
      ratesWeekendRate: 'Stawka weekendowa',
      ratesWeekendDayRate: 'Dzienna stawka weekendowa',
      ratesFoodText: 'Jedzenie i napoje (w tym herbata, kawa, napary) Cena za osobę',
      ratesContinentalBreakfast: 'Śniadanie kontynentalne',
      ratesFullBreakfast: 'Pełne śniadanie',
      ratesLunch: 'Obiad',
      ratesDinner: 'Kolacja',
      ratesAgreementText:
        'Wpisanie tych stawek spowoduje zawarcie wiążącej umowy między Grupą CMAC a Państwa hotelem, w związku z czym należy upewnić się, aby formularz wypełniła osoba do tego upoważniona. Hotel ponosi odpowiedzialność za osobę, która wypełni niniejszy formularz, a jeśli zostanie on wypełniony przez osobę do tego nieupoważnioną, nie będzie to miało wpływu na Grupę CMAC ani na jej zdolność do egzekwowania tych stawek.',
      ratesTermsConditionsText: 'Proszę zaznaczyć, aby potwierdzić, że przeczytałeś nasze ',
      ratesTermsConditionsLinkText: 'warunki umowy',
      submittedHeading: 'Przesłane przez',
      viewRatesTermsConditionsText: 'Kliknij tutaj, aby zobaczyć nasze',
      outstandingRateRequest: 'Oczekujący wniosek o stawkę',
    },
    cmacRef: 'Nr ref. CMAC',
    yourRef: 'Twój nr ref.',
    noNotificationEmailAddress: 'Nie masz adresu e-mail do powiadomień',
    leadMiles: 'Przebieg',
    fareNet: 'Opłata (Netto)',
    leadMilesCost: 'Koszt za przebieg (Netto)',
    totalCost: 'Koszt ogółem (Netto)',
    appliesOnDays: 'Obowiązuje w dniach',
    noVehicleRates: 'Brak stawek za pojazdy',
    markup: 'Narzut',
    addedPrice: 'Dodana Cena',
    noHolidayRates: 'Brak stawek wakacyjnych',
    standDownRates: 'Obniż stawki',
    noStandDownRates: 'Brak stawek zaniżonych',
    stoodDownWithin: 'Usiadł wewnątrz',
    minutesOfDepartureDate: 'minut od daty odlotu',
    ofOriginalCostPrice: 'pierwotnej ceny nabycia',
    ofOriginalCostPriceWhicheverIsHigher:
      'pierwotnej ceny nabycia, w zależności od tego, która z nich jest wyższa',
    waitingTimeRates: 'Stawki za czas oczekiwania',
    noWaitingTimeRates: 'Brak stawek za czas oczekiwania',
    minutesFree: 'minuty za darmo',
    minutes: 'minuty',
  },
  common: {
    about: 'O nas',
    accepted: 'Zaakceptowane',
    addComment: 'Dodaj koment.',
    add: 'Dodaj',
    addVehicle: 'Dodaj pojazd',
    actions: 'Działania',
    addXtoY: 'Dodaj {x} do {y}',
    agreed: 'Ustalone',
    agreementType: 'Rodzaj umowy',
    all: 'Wszystkie',
    back: 'Powrót',
    backToTop: 'Powrót do góry',
    cancel: 'Anuluj',
    close: 'Zamknij',
    comment: 'Komentarz',
    comments: 'Komentarze',
    completed: 'Ukończone',
    confirm: 'Potwierdź',
    continue: 'Kontynuuj',
    cost: 'Koszt',
    create: 'Sporządź',
    created: 'Sporządzone',
    createdAt: 'Sporządzono o',
    customer: 'Klient',
    date: 'Data',
    dateRange: 'Zakres dat',
    delete: 'Usuń',
    departureDateRange: 'Zakres dat wyjazdu',
    description: 'Opis',
    details: 'Szczegóły',
    disputed: 'Dyskutowane',
    distance: 'Odległość',
    documents: 'Dokumenty',
    download: 'Pobierz',
    edit: 'Edytuj',
    edited: 'Edytowane',
    editVehicle: 'Edytuj pojazd',
    email: 'E-mail',
    enabled: 'włączone',
    error: 'Błąd',
    example: 'Przykład',
    expired: 'Po terminie',
    expires: 'Mija termin',
    failed: 'Niepowodzenie',
    file: 'Plik',
    filename: 'Nazwa pliku',
    filters: 'Filtry',
    from: 'Od',
    full: 'Pełny',
    half: 'Połowa',
    home: 'Strona główna',
    hide: 'Ukryj',
    id: 'Nr ID',
    info: 'Informacje',
    important: 'Ważne',
    jobNo: 'Nr zlecenia',
    loading: 'Ładowanie',
    map: 'Mapa',
    messages: 'Wiadomości',
    miles: 'Mile',
    minutes: 'Minut(y)',
    mode: 'Tryb',
    name: 'Nazwisko',
    new: 'Nowe',
    no: 'Nr',
    none: 'Brak',
    notes: 'Uwagi',
    null: 'Zero',
    ok: 'Dobrze',
    open: 'Otwórz',
    organisation: 'Organizacja',
    organisations: 'Organizacje',
    original: 'Początkowe',
    others: 'Inne',
    password: 'Hasło',
    pending: 'Oczekujące',
    price: 'Cena',
    previouslyUploaded: 'Wcześniej przesłane',
    processed: 'Przetworzone',
    processing: 'Przetwarzane',
    protection: 'Ochrona',
    quantity: 'Ilość',
    raw: 'surowe',
    readonly: 'Tylko do odczytu',
    reference: 'Nr ref.',
    refresh: 'Odśwież',
    reset: 'Zresetuj',
    roles: 'Role',
    save: 'Zapisz',
    search: 'Szukaj',
    settings: 'Ustawienia',
    show: 'Pokaż',
    signedBy: 'Podpisał(a)',
    signedOn: 'Podpisano dnia',
    sortBy: 'Sortuj według',
    status: 'Status',
    submit: 'Prześlij',
    surcharge: 'Dopłata',
    test: 'Test',
    time: 'Godzina',
    title: 'Tytuł',
    total: 'Łącznie',
    type: 'Rodzaj',
    unknown: 'Nieznany',
    update: 'Aktualizuj',
    updated: 'Aktualizowane',
    updatedAt: 'Zaktualizowano o',
    upload: 'Prześlij',
    uploaded: 'Przesłane',
    user: 'Użytkownik',
    username: 'Nazwa użytkownika',
    users: 'Użytkownicy',
    website: 'Strona internetowa',
    weight: 'Waga',
    yes: 'Tak',
    next: 'Następny',
    to: 'Do',
    hour: 'godzina',
    mile: 'mila',
    or: 'lub',
  },
  currencies: {
    gbp: '£',
    eur: '€',
  },
  datetime: {
    monday: 'Poniedziałek',
    tuesday: 'Wtorek',
    wednesday: 'Środa',
    thursday: 'Czwartek',
    friday: 'Piątek',
    saturday: 'Sobota',
    sunday: 'Niedziela',
    january: 'Styczeń',
    february: 'Luty',
    march: 'Marzec',
    april: 'Kwiecień',
    may: 'Maj',
    june: 'Czerwiec',
    july: 'Lipiec',
    august: 'Sierpień',
    september: 'Wrzesień',
    october: 'Październik',
    november: 'Listopad',
    december: 'Grudzień',
    today: 'Dzisiaj',
  },
  docs: {
    documentation: 'Dokumentacja',
    faq: 'FAQ',
    frequentlyAskedQuestions: 'Najczęściej zadawane pytania',
    tutorials: 'Samouczki',
  },
  errors: {
    301: 'Nie masz uprawnień do tego zasobu.',
    400: 'Przepraszamy, żądanie zakończone niepowodzeniem.',
    404: 'Przepraszamy, nie udało się znaleźć szukanego zasobu.',
    500: 'Serwer nie mógł przetworzyć tego żądania.',
    error: 'Błąd',
    nError: '{n} błąd',
    noResponseFromServer: 'Brak odpowiedzi z serwera',
    noUrl: 'Brak adresu URL',
    sorrySomethingWentWrong: 'Niestety coś poszło nie tak',
    uploadFailed: 'Przesłanie się nie powiodło',
    uploadFailedWithError: 'Przesłanie się nie powiodło, wystąpił błąd',
  },
  fields: {
    address: 'Adres',
    addresses: 'Adresy',
    addressLine1: '1 linijka adresu',
    addressLine2: '2 linijka adresu',
    addressLine3: '3 linijka adresu',
    addressLookup: 'Wyszukiwanie Adresu',
    city: 'Miasto',
    country: 'Kraj',
    county: 'Województwo',
    description: 'Opis',
    driverName: 'Nazwa kierowcy',
    driverPhone: 'Numer telefonu kierowcy',
    driverRegistration: 'Rejestracja kierowcy',
    email: 'E-mail',
    emailAddress: 'Adres e-mail',
    emailAddresses: 'Adresy e-mail',
    home: 'Strona główna',
    mobile: 'Telefon komórkowy',
    name: 'Nazwisko',
    number: 'Numer',
    phoneNumber: 'Numer telefonu',
    postCode: 'Kod pocztowy',
    region: 'Region',
    registrationNumber: 'Numer rejestracyjny',
    telephoneNumber: 'Numer telefonu',
    telephoneNumbers: 'Numery telefonu',
    title: 'Tytuł',
    titles: {
      dr: 'Dr',
      lady: 'Lady',
      lord: 'Lord',
      mr: 'Pan',
      miss: 'Pani',
      mrs: 'Pani',
      ms: 'Pani',
      mx: 'X',
      prof: 'Profesor',
      sir: 'Pan',
    },
    town: 'Miasto',
    work: 'Praca',
  },
  onboarding: {
    documents: {
      public_insurance: 'Zaświadczenie o ubezpieczeniu od odpowiedzialności cywilnej',
      liability: 'Certyfikat ubezpieczenia odpowiedzialności pracodawcy',
      motor_insurance: 'Certyfikat ubezpieczenia pojazdu',
      gdpr: 'Polityka RODO',
      small_operator_license: 'Licencja operatora - małe pojazdy',
      large_operator_license: 'Licencja operatora - pojazdy duże',
      upload: 'Wyślij dokument',
      download: 'Pobierz dokument',
      file: 'Nazwa pliku',
      document: 'Rodzaj dokumentu',
      created: 'Utworzony',
      expires: 'Ważny do',
    },
    agreement: 'Przeczytałem i akceptuję warunki wydrukowane powyżej.',
    mustAgree: 'Aby móc korzystać z aplikacji, musisz zaakceptować regulamin.',
    welcomeHeader: 'Witamy w procesie rejestracji dostawców CMAC',
    country: 'Nazwa kraju',
    legalCompanyName: 'Nazwa prawna firmy',
    tradingName: 'Nazwa handlowa',
    companyRegistrationNumber: 'Numer rejestracyjny firmy',
    registeredAddress: 'Siedziba / Oficjalne biuro',
    operatingAddress: 'Adres operacyjny',
    currency: 'Waluta',
    logOut: 'Wyloguj',
    smallVehicle: 'Mały pojazd (taksówka, MDV, DDA)',
    largeVehicle: 'Duży pojazd (minibus, autokar)',
    fleetSize: 'Wielkość floty',
    emailAddress: 'Adres e-mail',
    mainContactNumber: 'Wprowadź hasło...',
    primaryEmailAddress: 'Główny adres e-mail',
    successResult: 'Konto zostało pomyślnie utworzone, sprawdź swoją skrzynkę odbiorczą',
    failResult:
      'W tej chwili nie możemy utworzyć dla Ciebie konta, ponieważ konto z podanymi przez Ciebie danymi już istnieje. Aby uzyskać więcej informacji, prosimy o kontakt na adres vendor.relations@cmacgroup.com.',

    progressHeader: 'Dziękujemy za przesłanie swoich danych i chęć zostania dostawcą CMAC.',
    progressMainText:
      'Aby móc kontynuować proces aplikacji, upewnij się, że wszystkie sekcje z danymi finansowymi oraz dokumenty rekrutacyjne zostały wypełnione.',
    progressSubText:
      'Po otrzymaniu tych informacji będziemy mogli przejść do następnego etapu rekrutacji.',
    progressBarText: 'Postęp rekrutacji',
    progressCompleted:
      'Dziękujemy za przesłanie wszystkich swoich dokumentów. Jeden z naszych pracowników przejrzy teraz Twoje zgłoszenie i skontaktuje się wkrótce, aby przejść do następnego kroku.',
    rejected:
      'Niestety Twoja aplikacja nie powiodła się. Prosimy o kontakt z naszym zespołem ds. relacji z dostawcami w celu uzyskania dalszych informacji.',
  },
  plurals: {
    comments: 'Komentarz | Komentarze',
    journeys: 'Podróż | Podróże',
    results: 'Wynik | Wyniki',
    resultsFound: 'Znaleziono wynik | Znaleziono wyniki',
    rows: 'Wiersz | Wiersze',
    suppliers: 'Dostawca | Dostawcy',
  },
  ui: {
    anNewVersionOfTheApplicationIsAvailable: 'Dostępna jest nowa wersja aplikacji',
    appName: 'Portal dostawcy',
    approve: 'Zatwierdź',
    approveAllSelectedRows: 'Zatwierdź wszystkie zaznaczone wiersze',
    changeProfilePicture: 'Zmień zdjęcie profilowe',
    chooseX: 'Wybierz {x}',
    clear: 'Wyczyść',
    clearFilters: 'Wyczyść filtry',
    clickToCopyToClipboard: 'Kliknij, aby skopiować do schowka',
    clickToSortByX: 'Kliknij, aby posortować według {x}',
    closeDrawer: 'Zamknij szufladę',
    currentlyUnavailable: 'Obecnie niedostępne',
    dashboard: 'Pulpit',
    document: 'Dokument',
    dragYourXHereToUploadIt: 'Przeciągnij swój {x} tutaj, aby go przesłać',
    export: 'Eksportuj',
    exportToCsv: 'Eksportuj do CSV',
    file: 'Plik',
    files: 'Pliki',
    fileSizeTooBig: 'Zbyt duży rozmiar pliku',
    footer: 'Stopka',
    home: 'Strona główna',
    homePage: 'Strona główna',
    itemsPerPage: 'Liczba elementów na stronę',
    navigation: 'Nawigacja',
    noDetailsToDisplay: 'Brak szczegółów do wyświetlenia',
    noFileSelected: 'Nie wybrano pliku',
    noResultsToShow: 'Brak wyników do wyświetlenia',
    noXProvided: 'Nie podano {x}',
    orClickToBrowse: 'Lub kliknij, aby przeglądać',
    page: 'Strona',
    pageSize: 'Rozmiar strony',
    pleaseSeletFromTheFollowing: 'Proszę wybrać spośród następujących',
    pleaseSelectAnX: 'Proszę wybrać {x}',
    pleaseSelectAX: 'Proszę wybrać {x}',
    pleaseSelectNXOnly: 'Proszę wybrać tylko {n} {x}',
    profilePicture: 'Zdjęcie profilowe',
    resultsPerPage: 'Liczba wyników na stronę',
    saveChanges: 'Zapisz zmiany',
    selectAll: 'Zaznacz wszystko',
    selectX: 'Zaznacz {x}',
    selectADifferentX: 'Zaznacz inny {x}',
    spreadsheet: 'Arkusz',
    theXHasBeenDeleted: '{x} zostało usunięte',
    thisWillPutXInDispute: 'To skieruje {x} do dyskusji',
    updateX: 'Aktualizuj {x}',
    uploadBeingProcessed: 'Trwa przetwarzanie przesyłania',
    uploadStatus: 'Stan przesyłania',
    uploading: 'Trwa przesyłanie',
    uploadNewX: 'Prześlij nowy {x}',
    uploadX: 'Prześlij {x}',
    userProfile: 'Profil użytkownika',
    version: 'Wersja',
    xMissing: 'brak {x}',
    policySignedX: 'Dziękujemy za podpisanie {x}',
  },
  validations: {
    canOnlyContainLettersAndNumbers: 'Może zawierać tylko litery i cyfry',
    canOnlyContainLettersNumbersAndTheFollowingCharactersX:
      'Może zawierać tylko litery, cyfry i następujące znaki: {x}',
    canOnlyContainNumbers: 'Może zawierać tylko cyfry',
    checkboxConfirm: 'Proszę zaznaczyć to pole, aby potwierdzić akceptację warunków umowy',
    maxNCharacters: 'Maks. {n} znaków',
    minNCharacters: 'Min {n} znaków',
    mustBeAnInteger: 'Musi być liczbą całkowitą',
    mustBeAFloat: 'Musi być liczbą dziesiętną',
    mustBeAValidEmailAddress: 'Musi być poprawnym adresem e-mail',
    mustBeAValidTelephoneNumber: 'Musi być prawidłowym numerem telefonu',
    mustBeAWholeNumber: 'Musi być liczbą całkowitą',
    mustBeExactlyNCharacters: 'Musi posiadać dokładnie {n} znaków',
    mustBeGreaterThanN: 'Musi być większe od {n}',
    mustBeGreaterThanOrEqualToN: 'Musi być większe lub równe {n}',
    mustBeLessThanN: 'Musi być mniejsze od {n}',
    mustBeLessThanOrEqualToN: 'Musi być mniejsze lub równe {n}',
    mustBeRoundedToExactlyNDecimalPlaces: 'Należy zaokrąglić dokładnie do {n} miejsca po przecinku',
    mustBeRoundedToNDecimalPlaces: 'Należy zaokrąglić do {n} miejsca po przecinku',
    mustHaveASingleDecimalPoint: 'Musi mieć jedno miejsce po przecinku',
    requiresBooleanValue: 'Wymaga wartości logicznej',
    thisDateMustBeInTheFuture: 'Ta data musi być w przyszłości',
    thisDateMustBeInThePast: 'Ta data musi być w przeszłości',
    thisExceedsTheLeadMileageLimit: 'Przekroczono limit kilometrówki bez pasażera',
    thisFieldIsRequired: 'To pole jest wymagane',
    thisFieldMustBeAValidDate: 'To pole wymaga podania prawidłowej daty',
    thisFieldMustContainAX: 'To pole musi zawierać {x}',
    thisFieldMustContainAnX: 'To pole musi zawierać {x}',
    mustBeUkBankSortCode: 'Numer sortowniczy musi być w formacie 00-00-00',
    mustBeUkBankAccountNumber:
      'Numer konta bankowego w Wielkiej Brytanii musi składać się z 8 cyfr',
    mustBeAtLeastOneChecked: 'Zaznacz jedną lub obie wartości',
  },
  invoiceUpload: {
    invoiceNo: 'Nr faktury',
    reference: 'Nr ref.',
    uploadDate: 'Data przesłania',
    invoiceDate: 'Data wystawienia faktury',
    dueDate: 'Termin płatności',
    total: 'Łącznie',
    currency: 'Waluta',
    status: 'Status',
    comments: 'Komentarze',
    file: 'Plik',
    selectJourneys: 'Wybierz podróże',
    uploadInvoice: 'Prześlij fakturę',
    confirm: 'Potwierdź',
    uploadingAs: 'Obecnie przesyłasz jako',
    atLeastOneJourney: 'Musisz wybrać przynajmniej jedną podróż',
    errorUploadingFile: 'Wystąpił błąd podczas przesyłania i analizowania pliku.',
    uploadInFormat: 'Prosimy o przesłanie faktury w formacie PDF.',
    selectFile: 'Proszę wybrać plik do przesłania',
    uploadingAnalyzing: 'Trwa przesyłanie i analizowanie',
    invoiceReference: 'Nr ref. faktury',
    invoice: 'Faktura',
    cmacReference: 'Nr ref. CMAC',
    yourReference: 'Twój nr ref.',
    journeysSelectionJourney: 'Podróż | Podróże',
    journeySelectionSelected: 'wybrane',
    net: 'Netto',
    tax: 'Podatek',
    gross: 'Brutto',
    expenses: 'Wydatki',
    details: 'Szczegóły',
    journey: 'Podróż',
    departureDate: 'Data wyjazdu',
    cmacRef: 'Nr ref. CMAC',
    yourRef: 'Twój nr ref.',
    invoiceAccepted: 'Faktura zatwierdzona i zaakceptowana',
    invoiceDoesNotMatch: 'Sprawdź i prześlij ponownie',
    stillUpload: 'Nadal możesz przesłać tę fakturę, ale będzie ona wymagała sprawdzenia. ',
    paymentDelayed: 'Płatność opóźni się do zakończenia tej operacji.',
    totalNetDoesNotMatch:
      'Całkowita kwota netto na fakturze nie jest zgodna z całkowitą ceną netto za wszystkie wybrane przejazdy.',
    expected: 'Oczekiwane',
    totalGross: 'Łączna kwota brutto',
    totalNet: 'Łączna kwota netto',
    totalTax: 'Łączna kwota podatku',
    notFound: 'Nie znaleziono',
    invoiced: 'Zafakturowane',
    underDispute: 'W trakcie dyskusji',
    notUnique: 'Plik o tej nazwie już istnieje. Wybierz inną nazwę pliku.',
    invalidCharacters: 'Nazwa pliku zawiera nieprawidłowe znaki. Poniższe nie są dozwolone.',
    invalidFileExtension: 'Ten typ pliku jest nieprawidłowy. Dozwolone są następujące typy plików.',
    cancelled: 'Anulowano',
    journeyCost: 'Koszt trasy (netto)',
    leadMilesCost: 'Koszt za przebieg (netto)',
    waitingTimeCost: 'Koszt oczekiwania (netto)',
    expensesCost: 'Koszty dodatkowe (netto)',
    greenFeeCost: 'Koszt opłaty ekologicznej (netto)',
    totalCost: 'Koszt ogółem (netto)',
    downloadJourneyData: 'Kliknij tutaj, aby pobrać dane dla wybranych tras.',
    paid: 'Zapłacono',
    excludedJourneys: 'Proces ten wyklucza przejazdy, które są obecnie przedmiotem sporu.',
    rejectedStatus: 'Odrzucony',
    createPdfInvoice: 'Utwórz Fakturę PDF',
    createPdf: {
      title: 'Utwórz Fakturę PDF dla wybranych podróży',
      invoiceNumber: 'Numer Faktury',
      invoiceDate: 'Data Faktury',
      chooseAddress: 'Proszę wybrać adres',
      registerDetails: {
        title: 'Szczegóły Rejestracji',
        heading: 'Aby utworzyć Twoją fakturę, potrzebujemy kilku dodatkowych informacji.',
        financial: {
          heading: 'Postępuj zgodnie z poniższymi krokami, aby dodać swoje dane finansowe:',
          step1: 'Wybierz ikonę menu hamburger {x} znajdującą się w prawym górnym rogu ekranu',
          step2: "Następnie wybierz 'Profil'.",
          step3: "W sekcji 'Dane Finansowe', kliknij przycisk edycji {x} i wprowadź swoje dane.",
        },
        address: {
          heading: 'Postępuj zgodnie z poniższymi krokami, aby dodać adres swojej firmy:',
          step1: 'Wybierz ikonę menu hamburger {x} znajdującą się w prawym górnym rogu ekranu.',
          step2: "Następnie wybierz 'Profil'.",
          step3: "W sekcji 'Adresy', kliknij przycisk plus {x} i wprowadź swoje dane.",
        },
      },
    },
    separateInvoicesRequired:
      'Podróże z pojazdami o liczbie miejsc powyżej 10 muszą być fakturowane oddzielnie od podróży z pojazdami o liczbie miejsc poniżej 10.',
    invoiceNumberFieldNameLabel: 'Nazwa pola numeru faktury',
    totalNetAmountFieldNameLabel: 'Nazwa pola łącznej kwoty netto',
  },
  compliance: {
    introduction:
      'Poniżej znajduje się podsumowanie kluczowych dokumentów, które muszą być gotowe i zarejestrowane u nas. Jeśli masz pytania dotyczące któregokolwiek z tych dokumentów, skontaktuj się z nami pod numerem 03333 207 100',
    noRulesFound: 'Nie znaleziono zasad zgodności',
  },
  contactItem: {
    generalManager: 'Główny menadżer',
    reception24Hour: 'Recepcja 24-godzinna',
    revenueSales: 'Przychody / Sprzedaż',
    twentyFourHourEmail: '24-godzinny e-mail',
    notification: 'Powiadomienie',
    other: 'Inny',
  },
}
