export default {
  navbar: {
    userGuide: 'Aide & Guides',
  },
  settings: {
    emailTypes: {
      generalManager: 'Directeur Général',
      reception: 'Réception/Accueil',
      sales: 'Revenu/Ventes',
      email24: 'Email 24 heures',
      notification: 'Notification',
      other: 'Autre',
    },
  },
  auth: {
    emailAndPasswordDoNotMatchOurRecords:
      'L’adresse électronique et le mot de passe ne correspondent pas à ceux contenus dans nos dossiers',
    forgottenYourPassword: 'Mot de passe oublié ?',
    forgottenYourUsername: 'Nom d’utilisateur oublié ?',
    helpUsFindYourUsername:
      'Veuillez fournir les renseignements demandés dans le formulaire ci-dessous pour nous aider à trouver votre nom d’utilisateur.',
    helpUsResetYourPassword:
      'Veuillez nous fournir votre nom d’utilisateur pour nous permettre de réinitialiser votre mot de passe.',

    login: 'Connexion',

    logIn: 'Se connecter',

    logout: 'Déconnexion',

    logOut: 'Se déconnecter',

    password: 'Mot de passe',

    passwordReset: 'Réinitialisation du mot de passe',

    pleaseEnterThePrimaryEmailAddress:
      'Veuillez saisir l’adresse électronique principale de votre compte.',

    pleaseEnterYourUsername: 'Veuillez saisir votre nom d’utilisateur...',

    pleaseEnterYourPassword: 'Veuillez saisir votre mot de passe...',

    primaryEmailAddress: 'Adresse e-mail principale',

    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'Le système n’a malheureusement pas pu vous authentifier. Cela est généralement dû au fait que votre session a expiré.  Veuillez vous connecter à nouveau pour continuer.',

    username: 'Nom d’utilisateur',

    usernameReminder: 'Rappel de l’identifiant',

    youAreBeingLoggedOut: 'Vous êtes déconnecté.',

    yourSessionHasExpired: 'Votre session a expiré.  Veuillez vous connecter à nouveau.',
  },
  availability: {
    availability: 'Disponibilité',

    addAvailability: 'Ajoutez votre disponibilité',

    addingAvailability: 'Ajout de disponibilité',

    accessible: 'Accessible',

    double: 'Double',

    family: 'Familiale',

    single: 'Simple',

    twin: 'À deux lits',

    room: 'Chambre',

    rooms: 'Chambres',

    numberAvailable: 'Quantité disponible',

    noCmacRate: 'Pas de taux CMAC',

    rate: 'Taux',

    useCmacAgreedRates: 'Utilisez les taux convenus avec CMAC',

    fullyBooked: 'Complet',

    helpText1:
      'Pour connaître les disponibilités, sélectionnez une date ou une fourchette de dates.',

    helpText2: 'Pour sélectionner une date, cliquez dans la case correspondante.',

    helpText3: 'Pour sélectionner une série de dates, cliquer sur une deuxième case distincte.',

    helpText4:
      'Les dates sélectionnées apparaissent en rouge.  Pour effacer une date que vous avez sélectionnée, cliquer sur la case correspondant à cette date.',

    helpText5:
      'Cliquer sur le bouton « Ajouter une disponibilité » et entrer le nombre total de chambres disponibles.  Si vous avez déjà soumis des tarifs, nous utiliserons les tarifs convenus avec CMAC.  Dans le cas contraire, veuillez indiquer le tarif de la chambre seule pour la disponibilité.',

    helpText6:
      'Vous pouvez ajouter plusieurs entrées pour la même date si vous avez des chambres disponibles à différents tarifs.',

    publicHeader: 'Groupe CMAC - Demande de disponibilité - ',
    publicInstruction1:
      'Veuillez indiquer le nombre de chaque type disponible et cliquez sur « Soumettre »',

    publicInstruction2:
      'Vous pouvez toujours utiliser ce lien pour revenir plus tard et mettre à jour ces informations. Il expirera le',

    ratesIncludeTax: 'Veuillez vous assurer que le tarif INCLUT les taxes / la TVA.',
    submittedBy: 'Proposé par ',
    updatedBy: 'Mis à jour par ',
    specificallyFor: 'Spécifiquement pour ',
    parkFlyRoomOnly: 'Chambre Sleep & Fly uniquement',
    parkFly4Bundle: 'Chambre Sleep & Fly + Parking 4 Jours',
    parkFly8Bundle: 'Chambre Sleep & Fly + Parking 8 Jours',
    parkFly15Bundle: 'Chambre Sleep & Fly + 15 jours de parking',
    parkFlyToCloseOut:
      'Pour clôturer une date, veuillez désactiver les éléments concernés. Pour réactiver la réservation, vous devrez télécharger à nouveau votre feuille de calcul de tarification.',
  },
  cmac: {
    actionedBy: 'Actionné par',

    addAddress: 'Ajouter une Adresse',

    addEmailAddress: 'Ajouter une Adresse Email',

    addTelephoneNumber: 'Ajouter un Numéro de Téléphone',

    addAComment: 'Ajouter un commentaire',

    addComment: 'Ajouter un commentaire',

    addExpense: 'Ajouter Dépense',

    addingAComment:
      'L’ajout d’un commentaire créera un litige sur ce voyage. À n’utiliser que pour les différences non indiquées sur la ligne de trajet.  Veuillez indiquer tous les renseignements, par exemple le kilométrage et la raison de la demande.',

    agreed: 'Accepté',

    allOpen: 'Tout ouvert',

    amend: 'Modifier',

    applies: 'S’applique',

    apply: 'Appliquer',

    assignedOrganisation: 'Organisation assignée',

    availability: 'Disponibilité',

    awaitingResponseFromSupplier: 'En attente de réponse du fournisseur',

    billingAddress: 'Adresse de Facturation',

    bulkUpload: 'Téléchargement en bloc',

    bulkUploadHistory: 'Historique des téléchargements en bloc',

    cancelled: 'Annulé',

    closed: 'Fermé',

    cmac: 'CMAC',

    cmacReference: 'Référence CMAC',

    companyDetails: 'Coordonnées de la société',

    companyFinancialDetails: 'Données financières',

    companyOnBoardingDocuments: 'Documents d’intégration de la société',

    companyNumber: 'Numéro de société',

    compliance: 'Conformité',

    costConfirmation: 'Confirmation des coûts',

    costVatBreakDown: 'Coût de ventilation de la TVA',

    coverage: 'Couverture',

    coverageAreas: 'Zones de couverture',

    currentlyImpersonating: 'Currently impersonating',

    customDates: 'Dates personnalisées',

    customerName: 'Nom du client',

    customerOrganisation: 'Organisation du client',

    departureDate: 'Date de départ',

    depotAddress: 'Adresse du Dépôt',

    dispute: 'Litige',

    disputed: 'Litigieux',

    disputeDetails: 'Détails du litige',

    disputeEntry: 'Saisie du litige',

    disputeEntries: 'Saisies des litiges',

    disputeId: 'Identifiant du litige',

    disputes: 'Litiges',

    disputesAwaitingAction: "Litiges En Attente d'Action",

    documentType: 'Type de document',

    driver: 'Chauffeur',

    driverDetails: 'Coordonnées du chauffeur',

    editAddress: 'Modifier l’Adresse',

    editEmailAddress: 'Modifier l’Adresse Email',

    editJourney: 'Modifier le trajet',

    editTelephoneNumber: 'Modifier le Numéro de Téléphone',

    editOpenJourneys: 'Modifier les parcours ouverts',

    endImpersonation: 'End impersonation',
    endTime: 'Heure de fin',

    expenses: 'Frais',

    expenseItem: {
      meetAndGreet: 'Rencontre et salutation',
      parking: 'Stationnement',
      toll: 'Péage',
      greenFee: 'Taxe écologique',
      misc: 'Divers',
      amount: 'Montant',
    },

    expired: 'Expiré',

    expiryDate: 'Date d’expiration',

    expiryDateRequired: 'Date d’expiration requise',

    extras: 'Suppléments',

    fare: 'Tarif',

    farePriceCalculation: 'Calcul du prix du trajet',

    financialDetails: {
      accountName: 'Nom du Compte',
      bankAddress: 'Adresse de la Banque',
      ibanNumber: 'Numéro IBAN',
      currency: 'Devise',
    },

    fixedPrices: 'Prix fixes',

    greenFee: 'Frais d’entrée en ZFE',

    holidayRates: 'Tarifs jours fériés',

    holidays: 'Jours fériés',

    holidayUplift: 'Élévation des vacances',

    holidayUpliftDescription:
      'Ces dates personnalisées s’appliquent à TOUTES les entrées de la carte tarifaire, à moins qu’elles ne soient remplacées par une date personnalisée spécifique à l’entrée',

    hotelMarkupRates: 'Taux de majoration des hôtels',

    hotelProfile: 'Profil de l’hôtel',

    hotelRatesTitle: 'Taux des hôtels',

    ifYouNeedToChangeHowWeKnowYouInOurSystemPleaseCallTheSupplierRelationsTeamOn:
      'Si vous souhaitez modifier la façon dont vous êtes connu dans notre système, veuillez appeler l’équipe chargée des relations avec les fournisseurs',

    impersonating: 'Impersonating',
    inProgress: 'En cours',

    invoiceId: 'Identifiant de la facture',

    invoices: 'Factures',

    job: 'Course | Courses',

    jobEntryId: 'Identifiant de la course',

    jobTypes: 'Types de courses',

    journey: 'Trajet',

    journeys: 'Trajets',

    journeyChargeMethod: 'Méthode de calcul du prix des trajets',

    lastUpdated: 'Dernière mise à jour',

    leadMileage: 'Kilométrage à vide',

    leadMileageCost: 'Coût du kilométrage à vide',

    mileage: 'Kilométrage',

    mileageRates: 'Taux kilométriques',

    minimumPrice: 'Prix minimum',

    noCustomDates: 'Pas de dates personnalisées',

    noOfPax: 'Nbr de pass.',

    open: 'Ouvert',

    orAlternativelyEmail: 'Ou également envoyer un e-mail',

    otherOrganisations: 'Autres organisations',

    passenger: 'Passager',

    parking: 'Parking',

    parkingExpenses: 'Frais de stationnement',

    pax: 'Pax',

    percentageOfOriginalCostPrice: 'Pourcentage du prix de revient initial',

    permits: {
      currentHeading: 'PERMIS ACTUELS',

      openHeading: 'OUVERT À CANDIDATURE',

      title: 'Permis',

      claimed: 'Réclamé',

      status: 'Statut',

      expires: 'Expire',

      apply: 'Postuler maintenant',

      outstandingAmendment: 'Ce permis fait l’objet d’une demande de modification en attente.',

      noClaimedPermits: 'Aucune demande de permis.',

      noOpenApplications: 'Aucune candidatures ouverte.',

      download: 'Télécharger',

      print: 'Imprimer le permis',

      amend: 'Modifier',
    },
    pickupDateTime: 'Date/Heure de prise',

    pleaseAddAComment: 'Veuillez ajouter un commentaire',

    pleaseContactXForAssistance: 'Veuillez contacter {x} pour obtenir de l’aide',

    pleaseSelectAXToImpersonate: 'Please select a {x} to impersonate',
    policiesAndTerms: 'Politiques et modalités',

    pricingInformation: 'Informations tarifaires',

    processing: 'Traitement',

    profile: 'Profil',

    psvarStatus: 'Statut Psvar',

    rate: 'Taux',

    rateCards: 'Cartes de tarifs',

    referredToOperations: 'Renvoyé aux opérations',

    referredToSupplierRelations: 'Renvoyé aux relations avec les fournisseurs',

    referredToPricingManager: 'Référé au responsable des tarifs',

    regions: 'Régions',

    registration: 'Immatriculation',

    responseReceivedFromSupplier: 'Réponse reçue du fournisseur',

    selectDocumentType: 'Sélectionner le type de document',

    selectUserToImpersonate: 'Select user to impersonate',
    standDownChargeMethod: 'Méthode de calcul des frais d’annulation',

    standDownPrice: 'Frais d’annulation',

    startTime: 'Heure de départ',

    statementId: 'Identifiant de la déclaration',

    stoodDown: 'Annulé',

    stoodDownWithinXMinutesOfDepartureDate:
      'Annulé dans les {x} minutes précédant la date de départ',

    summary: 'Résumé',

    supplierName: 'Nom du fournisseur',

    supplierRef: 'Réf. fournisseur',

    termsAndConditions: 'Conditions générales',

    thankYouForLoggingInToTheSupplierPortal: 'Merci de vous connecter au portail des fournisseurs',

    tierType: 'Type d’échelon',

    updateFailed: 'Échec de la mise à jour',

    updateSuccessful: 'Mise à jour réussie',

    userDetails: 'Coordonnées de l’utilisateur',

    hotelDetails: 'Coordonnées de l’hôtel',

    vatNumber: 'Numéro de TVA',

    vatTreatment: 'Traitement de la TVA',

    vehicle: 'Véhicule',

    vehicleRegistration: 'Immatriculation des véhicules',

    vehicleRates: 'Tarifs des véhicules',

    vias: 'Vias',

    viewAll: 'Tout voir',

    waypoint: 'Point de passage | Points de passage',

    waitingTime: 'Temps d’attente',

    waitingTimeCost: 'Coût du temps d’attente',

    waitingTimeFree: 'Temps d’attente gratuit',

    waitingTimeMinutes: 'Minutes d’attente',

    waitingTimeRate: 'Tarif du temps d’attente',

    withinXMinutes: 'Dans les {x} minutes',

    vehicles: 'Véhicules',

    vehicleType: 'Type de véhicule',

    yourAccountIsCurrentlyInactive: 'Votre compte est actuellement inactif',

    yourReference: 'Votre référence',

    total: 'Total',

    invoiceuploads: 'Téléchargements de factures',

    hotelRates: {
      validBetween: 'Valable entre',

      addNewRate: 'Ajouter des tarifs',

      addNewRateText: 'Cette organisation fait l’objet d’une nouvelle demande de tarif en attente.',

      noRatesTextPrepend:
        'Nous n’avons pas de tarifs en vigueur pour votre organisation. Veuillez nous contacter à l’adresse suivante',

      noRatesTextAppend: 'afin de nous soumettre vos tarifs.',

      hasRatesText:
        'Voici les taux que nous avons actuellement dans notre système.  Si vous souhaitez les modifier, veuillez nous contacter à l’adresse suivante',

      profile: 'Profil',

      profileStarRating: 'Nombre d’étoiles',

      profileUnkown: 'Inconnu',

      profileYes: 'Oui',

      profileNo: 'Non',

      profileYesFree: 'Oui Gratuit',

      profileNumberOfRooms: 'Nombre de chambres',

      profileAccessibleRooms: 'Chambres accessibles',

      profileFamilyRooms: 'Chambres familiales',

      profileHotelBus: 'Bus de l’hôtel',

      profileParking: 'Parking',

      profileSpa: 'Spa',
      profileSwimmingPool: 'Piscine',

      profileGym: 'Salle de sport',

      profileRestaurant: 'Restaurant',

      commissionRate: 'Taux de commission',

      commissionRooms: 'Chambres',

      commissionFood: 'Nourriture',

      commissionDynamic: 'Dynamique',

      editText: 'Carte tarifaire de l’hôtel',

      editProfileText: 'Modifier les coordonnées de l’hôtel',

      ratesValidDatesText: 'Les tarifs sont applicables à ces dates',

      ratesValidFrom: 'Valables à partir de',

      ratesValidTo: 'Valables jusqu’à',

      rates: 'Tarifs',

      ratesType: 'Type',

      ratesRoomText: 'Tarifs par chambre pour les passagers retardés et les équipages',

      ratesRoomType: 'Type de chambre',

      ratesMidweekText: 'Uniquement chambre en milieu de semaine (dim-jeu)',

      ratesDayLet: 'Location à la journée',

      ratesWeekendText: 'Uniquement chambre le week-end (ven-sam)',

      ratesRoomTwinDouble: 'À deux lits / double',

      ratesRoomSingle: 'Chambre individuelle',
      ratesRoomTriple: 'Triple',

      ratesRoomFamily: 'Familiale',

      ratesWeekdayRate: 'Tarif en semaine',

      ratesWeekdayDayRate: 'Tarif à la journée en semaine',

      ratesWeekendRate: 'Tarif week-end',

      ratesWeekendDayRate: 'Tarif à la journée le week-end',

      ratesFoodText: 'Nourriture et boissons (y compris thé, café, sirop) Prix par personne',

      ratesContinentalBreakfast: 'Petit-déjeuner continental',

      ratesFullBreakfast: 'Petit-déjeuner complet',

      ratesLunch: 'Déjeuner',

      ratesDinner: 'Dîner',

      ratesAgreementText:
        'La saisie de ces tarifs créera un accord contractuel entre le groupe CMAC et votre hôtel et vous devrez donc veiller à ce que seule une personne autorisée remplisse le formulaire. L’hôtel est responsable de la personne qui remplit le formulaire et si une personne non autorisée remplit le formulaire, cela n’aura pas d’incidence sur le groupe CMAC ou sur sa capacité à appliquer ces tarifs.',

      ratesTermsConditionsText: 'Veuillez cocher la case pour confirmer que vous avez lu nos',

      ratesTermsConditionsLinkText: 'conditions générales',

      submittedHeading: 'Soumis par',
      viewRatesTermsConditionsText: 'Cliquez ici pour consulter notre',

      outstandingRateRequest: 'Demande de tarif en attente',
    },
    cmacRef: 'Réf CMAC',

    yourRef: 'Votre réf',

    noNotificationEmailAddress: 'Vous n`avez pas d`adresse e-mail de notification',

    leadMiles: 'Miles Supplémentaires',

    fareNet: 'Tarif (Net)',

    leadMilesCost: 'Coût des miles supplémentaires (Net)',

    totalCost: 'Coût total (Net)',
    appliesOnDays: 'S`applique les jours',
    noVehicleRates: 'Pas de tarifs pour les véhicules',
    markup: 'Balisage',
    addedPrice: 'Prix Ajouté',
    noHolidayRates: 'Pas de tarifs vacances',
    standDownRates: 'Tarifs réduits',
    noStandDownRates: 'Pas de tarifs réduits',
    stoodDownWithin: 'S`est arrêté à l`intérieur',
    minutesOfDepartureDate: 'minutes de la date de départ',
    ofOriginalCostPrice: 'du prix de revient initial',
    ofOriginalCostPriceWhicheverIsHigher:
      'du prix de revient initial, le montant le plus élevé étant retenu',
    waitingTimeRates: 'Taux de temps d`attente',
    noWaitingTimeRates: 'Pas de taux de temps d`attente',
    minutesFree: 'minutes gratuites',
    minutes: 'minutes',
  },
  common: {
    about: 'À propos de',

    accepted: 'Accepté',

    addComment: 'Ajout. comm.',

    add: 'Ajouter',

    addVehicle: 'Ajouter un véhicule',

    actions: 'Actions',

    addXtoY: 'Ajouter {x} à {y}',

    agreed: 'Accepté',

    agreementType: 'Type d’accord',

    all: 'Tout',

    back: 'Retour',

    backToTop: 'Retour en haut de la page',

    cancel: 'Annuler',

    close: 'Fermer',

    comment: 'Faire un commentaire',

    comments: 'Commentaires',

    completed: 'Terminé',

    confirm: 'Confirmer',

    continue: 'Continuer',

    cost: 'Coût',

    create: 'Créer',

    created: 'Créé',

    createdAt: 'Créé à',

    customer: 'Client',

    date: 'Date',

    dateRange: 'Période',

    delete: 'Supprimer',

    departureDateRange: 'Période de départ',

    description: 'Description',

    details: 'Coordonnées',

    disputed: 'En litige',

    distance: 'Distance',

    documents: 'Documents',

    download: 'Télécharger',

    edit: 'Modifier',

    edited: 'Modifié',

    editVehicle: 'Éditer véhicule',

    email: 'Adresse e-mail',

    enabled: 'activé',

    error: 'Erreur',

    example: 'Exemple',

    expired: 'Expiré',

    expires: 'Expire',

    failed: 'Échec',

    file: 'Fichier',

    filename: 'Nom du fichier',

    filters: 'Filtres',

    from: 'À partir de',

    full: 'Complet',

    half: 'Moitié',

    home: 'Domicile',

    hide: 'Masquer',

    id: 'Identifiant',

    info: 'Information',

    important: 'Important',

    jobNo: 'Numéro de course',

    loading: 'Chargement',

    map: 'Carte',

    messages: 'Messages',

    miles: 'Kilomètres',

    minutes: 'Minutes',

    mode: 'Mode',

    name: 'Nom',

    new: 'Nouveau',

    no: 'Non',

    none: 'Aucun',

    notes: 'Notes',

    null: 'Nul',

    ok: 'OK',

    open: 'Ouvert',

    organisation: 'Organisation',

    organisations: 'Organisations',

    original: 'Original',

    others: 'Autres',

    password: 'Mot de passe',

    pending: 'En attente',

    price: 'Prix',

    previouslyUploaded: 'Téléchargé précédemment',

    processed: 'Traité',

    processing: 'En traitement',

    protection: 'Protection',

    quantity: 'Quantité',

    raw: 'Brut',

    readonly: 'En lecture seule',

    reference: 'Référence',

    refresh: 'Rafraîchir',

    reset: 'Réinitialiser',

    roles: 'Rôles',

    save: 'Sauvegarder',

    search: 'Effectuer une recherche',

    settings: 'Réglages',

    show: 'Afficher',

    signedBy: 'Signé par',

    signedOn: 'Signé le',

    sortBy: 'Trier par',

    status: 'Statut',

    submit: 'Soumettre',

    surcharge: 'Majoration',

    test: 'Test',

    time: 'Heure',

    title: 'Titre',

    to: 'À',

    total: 'Total',

    type: 'Type',

    unknown: 'Inconnu',

    update: 'Mettre à jour',

    updated: 'Mis à jour',

    updatedAt: 'Mis à jour à',

    upload: 'Télécharger',

    uploaded: 'Téléchargé',

    user: 'Utilisateur',

    username: 'Nom d’utilisateur',

    users: 'Utilisateurs',

    website: 'Site internet',

    weight: 'Poids',

    yes: 'Oui',

    next: 'Suivant',
    hour: 'heure',
    mile: 'mile',
    or: 'ou',
  },
  currencies: {
    gbp: '£',

    eur: '€',
  },
  datetime: {
    monday: 'lundi',

    tuesday: 'mardi',

    wednesday: 'mercredi',

    thursday: 'jeudi',

    friday: 'vendredi',

    saturday: 'samedi',

    sunday: 'dimanche',

    january: 'janvier',

    february: 'février',

    march: 'mars',

    april: 'avril',

    may: 'mai',

    june: 'juin',

    july: 'juillet',

    august: 'août',

    september: 'septembre',

    october: 'octobre',

    november: 'novembre',

    december: 'décembre',

    today: 'Aujourd’hui',
  },
  docs: {
    documentation: 'Documentation',

    faq: 'FAQ',

    frequentlyAskedQuestions: 'Foire aux questions',

    tutorials: 'Tutoriels',
  },
  errors: {
    301: 'Vous n’êtes pas autorisé à accéder à cette rubrique.',

    400: 'Désolé, votre demande n’a pas abouti.',

    404: 'Désolé, nous n’avons pas trouvé la rubrique que vous recherchiez.',

    500: 'Le serveur n’a pas pu traiter votre demande.',

    error: 'Erreur',

    nError: 'Erreur (n)',

    noResponseFromServer: 'Aucune réponse du serveur',

    noUrl: 'Pas d’adresse URL',

    sorrySomethingWentWrong: 'Désolé, un problème est survenu',

    uploadFailed: 'Échec du téléchargement',

    uploadFailedWithError: 'Échec du téléchargement causé par une erreur',
  },
  fields: {
    address: 'Adresse',

    addresses: 'Adresses',

    addressLine1: 'Adresse ligne 1',

    addressLine2: 'Adresse ligne 2',

    addressLine3: 'Adresse ligne 3',

    addressLookup: 'Recherche d’Adresse',

    city: 'Ville',

    country: 'Pays',

    county: 'Département',

    description: 'Description',

    driverName: 'Nom du conducteur',

    driverPhone: 'Numéro de téléphone du conducteur',

    driverRegistration: 'Immatriculation du conducteur',

    email: 'Adresse e-mail',

    emailAddress: 'Adresse e-mail',

    emailAddresses: 'Adresses e-mail',

    home: 'Domicile',

    mobile: 'Mobile',

    name: 'Nom',

    number: 'Numéro',

    phoneNumber: 'Numéro de téléphone',

    postCode: 'Code postal',

    region: 'Région',

    registrationNumber: 'Numéro d’immatriculation',

    telephoneNumber: 'Numéro de téléphone',

    telephoneNumbers: 'Numéros de téléphone',

    title: 'Civilité',

    titles: {
      dr: 'Dr',

      lady: 'Lady',

      lord: 'Lord',

      mr: 'M.',

      miss: 'Mlle',

      mrs: 'Mme',

      ms: 'Mme',

      mx: 'Mx',

      prof: 'Pr',

      sir: 'Monsieur',
    },
    town: 'Ville',

    work: 'Travail',
  },
  onboarding: {
    documents: {
      public_insurance: "Attestation d'assurance responsabilité civile",
      liability: "Attestation d'assurance responsabilité civile des employeurs",
      motor_insurance: "Certificat d'assurance automobile",
      gdpr: 'Politique RGPD',
      small_operator_license: 'Permis de conduire - petits véhicules',
      large_operator_license: 'Permis de conduire - gros véhicules',
      upload: 'Télécharger le document',
      download: 'Télécharger le document',
      file: 'Nom du fichier',
      document: 'Type de document',
      created: 'Créé le',
      expires: 'Expire le',
    },
    agreement: 'J`ai lu et j`accepte les termes et conditions imprimés ci-dessus.',
    mustAgree: 'Vous devez accepter les termes et conditions pour utiliser l`application.',
    welcomeHeader: 'Bienvenue sur le site d’inscription des fournisseurs de CMAC',

    country: 'Nom du pays',

    legalCompanyName: 'Raison sociale de l’entreprise',

    tradingName: 'Nom commercial',

    companyRegistrationNumber: 'Numéro d’immatriculation de la société',

    registeredAddress: 'Adresse du siège / Siège juridique',

    operatingAddress: 'Adresse opérationnelle',

    currency: 'Déconnexion',

    logOut: 'Se déconnecter',

    smallVehicle: 'Petit véhicule (taxi, VTC, taxi ambulance, etc.)',

    largeVehicle: 'Véhicule de grande taille (minibus, autobus)',

    fleetSize: 'Taille de la flotte',

    emailAddress: 'Adresse e-mail',

    mainContactNumber: 'Veuillez saisir votre mot de passe...',

    primaryEmailAddress: 'Adresse e-mail principale',

    successResult:
      'Votre compte a été créé avec succès, veuillez consulter votre boîte de réception.',

    failResult:
      'Nous ne sommes pas en mesure de créer votre compte pour le moment, un compte avec les informations que vous nous avez fournies existe déjà, veuillez contacter supplier.relations@cmacgroup.com pour plus d’informations.',

    progressHeader:
      'Nous vous remercions d’avoir soumis vos coordonnées pour devenir un fournisseur de CMAC.',

    progressMainText:
      'Pour que votre candidature soit prise en compte, veuillez vous assurer que toutes les parties des sections « Données financières » et « Documents d’intégration » de l’entreprise ci-dessous soient remplies.',

    progressSubText:
      'Une fois que nous aurons reçu ces informations, nous pourrons faire passer votre demande à l’étape suivante.',

    progressBarText: 'Processus d’intégration',

    progressCompleted:
      "Merci d'avoir soumis tous vos documents. Un membre de notre équipe examinera maintenant votre soumission et vous contactera bientôt pour passer à l'étape suivante.",

    rejected:
      "Malheureusement, votre demande n'a pas été acceptée. Veuillez contacter notre équipe des relations avec les fournisseurs pour plus d'informations.",
  },
  plurals: {
    comments: 'Commentaire / Commentaires',

    journeys: 'Trajet / Trajets',

    results: 'Résultat / Résultats',

    resultsFound: 'Résultat trouvé / Résultats trouvés',

    rows: 'Ligne | Lignes',

    suppliers: 'Fournisseur | Fournisseurs',
  },
  ui: {
    anNewVersionOfTheApplicationIsAvailable: 'Une nouvelle version de l’application est disponible',

    appName: 'Portail fournisseurs',

    approve: 'Valider',

    approveAllSelectedRows: 'Valider toutes les lignes sélectionnées',

    changeProfilePicture: 'Modifier l’image du profil',

    chooseX: 'Choisir {x}',

    clear: 'Supprimer',

    clearFilters: 'Supprimer les filtres',

    clickToCopyToClipboard: 'Cliquer pour copier dans le presse-papiers',

    clickToSortByX: 'Cliquer pour trier par {x}',

    closeDrawer: 'Fermer le tiroir',

    currentlyUnavailable: 'Indisponible actuellement',

    dashboard: 'Tableau de bord',

    document: 'Document',

    dragYourXHereToUploadIt: 'Faites glisser votre {x} ici pour le télécharger',
    export: 'Exporter',

    exportToCsv: 'Exporter au format CSV',
    file: 'Fichier',
    files: 'Fichiers',

    fileSizeTooBig: 'Taille du fichier trop importante',
    footer: 'Pied de page',

    home: 'Domicile',

    homePage: 'Página Principal',

    itemsPerPage: 'Articles par page',
    navigation: 'Navigation',

    noDetailsToDisplay: 'Aucune information à afficher',

    noFileSelected: 'Aucun fichier sélectionné',

    noResultsToShow: 'Aucun résultat à afficher',

    noXProvided: 'Aucun {x} n’a été fourni',

    orClickToBrowse: 'Ou cliquer pour naviguer',

    page: 'Page',

    pageSize: 'Format de la page',

    pleaseSeletFromTheFollowing: 'Veuillez choisir l’une des options suivantes',

    pleaseSelectAnX: 'Veuillez sélectionner un {x}',

    pleaseSelectAX: 'Veuillez sélectionner un {x}',

    pleaseSelectNXOnly: 'Veuillez sélectionner uniquement {n} {x}',

    profilePicture: 'Image du profil',

    resultsPerPage: 'Résultats par page',

    saveChanges: 'Sauvegarder les modifications',

    selectAll: 'Tout sélectionner',

    selectX: 'Sélectionner {x}',

    selectADifferentX: 'Sélectionner un autre {x}',

    spreadsheet: 'Feuille de calcul',

    theXHasBeenDeleted: 'Le {x} a été supprimé',
    thisWillPutXInDispute: 'Cela placera {x} en position de litige',

    updateX: 'Mettre à jour {x}',

    uploadBeingProcessed: 'Le téléchargement est en cours de traitement',

    uploadStatus: 'Statut du téléchargement',

    uploading: 'Téléchargement',

    uploadNewX: 'Télécharger un nouveau {x}',

    uploadX: 'Télécharger {x}',

    userProfile: 'Profil de l’utilisateur',

    version: 'version',

    xMissing: '{x} manquant',

    policySignedX: 'Merci d’avoir signé {x}',
  },
  validations: {
    canOnlyContainLettersAndNumbers: 'Ne peut que contenir des lettres et des chiffres',

    canOnlyContainLettersNumbersAndTheFollowingCharactersX:
      'Ne peut que contenir des lettres, des chiffres et les caractères suivants : {x}',
    canOnlyContainNumbers: 'Ne peut que contenir des chiffres',

    checkboxConfirm:
      'Veuillez cocher la case pour confirmer que vous acceptez nos conditions générales',

    maxNCharacters: 'Max {n} caractères',

    minNCharacters: 'Min {n} caractères',

    mustBeAnInteger: 'Doit être un nombre entier',

    mustBeAFloat: 'Doit être un nombre décimal',

    mustBeAValidEmailAddress: 'Doit être une adresse e-mail valide',

    mustBeAValidTelephoneNumber: 'Doit être un numéro de téléphone valide',

    mustBeAWholeNumber: 'Doit être un nombre entier',

    mustBeExactlyNCharacters: 'Doit être composé d’exactement {n} caractères',

    mustBeGreaterThanN: 'Doit être supérieur à {n}',

    mustBeGreaterThanOrEqualToN: 'Doit être supérieur ou égal à {n}',

    mustBeLessThanN: 'Doit être inférieur à {n}',

    mustBeLessThanOrEqualToN: 'Doit être inférieur ou égal à {n}',

    mustBeRoundedToExactlyNDecimalPlaces: 'Doit être arrondi à exactement {n} décimales',

    mustBeRoundedToNDecimalPlaces: 'Doit être arrondi à {n} décimales',

    mustHaveASingleDecimalPoint: 'Ne doit comporter qu’un seul point décimal',

    requiresBooleanValue: 'Requiert une valeur booléenne',

    thisDateMustBeInTheFuture: 'Cette date doit se situer dans le futur',

    thisDateMustBeInThePast: 'Cette date doit se situer dans le passé',

    thisExceedsTheLeadMileageLimit: 'Ceci dépasse la limite du kilométrage à vide',

    thisFieldIsRequired: 'Ce champ est obligatoire',
    thisFieldMustBeAValidDate: 'Ce champ doit contenir une date valide',

    thisFieldMustContainAX: 'Ce champ doit contenir un {x}',

    thisFieldMustContainAnX: 'Ce champ doit contenir un {x}',

    mustBeUkBankSortCode: 'Le code de tri de la banque doit être au format 00-00-00',

    mustBeUkBankAccountNumber: 'Le numéro de compte bancaire britannique doit comporter 8 chiffres',

    mustBeAtLeastOneChecked: 'Cochez une ou les deux valeurs',
  },
  invoiceUpload: {
    invoiceNo: 'Numéro de facture',

    reference: 'Référence',

    uploadDate: 'Date de téléchargement',

    invoiceDate: 'Date de facture',

    dueDate: 'Date d’échéance',

    total: 'Total',

    currency: 'Devise',

    status: 'Statut',

    comments: 'Commentaires',

    file: 'Fichier',

    selectJourneys: 'Sélectionner les trajets',
    uploadInvoice: 'Télécharger la facture',
    confirm: 'Confirmer',
    uploadingAs: 'Vous téléchargez actuellement en tant que',
    atLeastOneJourney: 'Vous devez sélectionner au moins un trajet',
    errorUploadingFile:
      'Une erreur s’est produite lors du téléchargement et de l’analyse du fichier',
    uploadInFormat: 'Veuillez télécharger votre facture au format PDF.',
    selectFile: 'Veuillez sélectionner un fichier à télécharger',
    uploadingAnalyzing: 'En cours de téléchargement et d’analyse',
    invoiceReference: 'Référence de la facture',
    invoice: 'Facture',
    cmacReference: 'Référence CMAC',
    yourReference: 'Votre référence',
    journeysSelectionJourney: 'Trajet / Trajets',
    journeySelectionSelected: 'Sélectionné',
    net: 'Net',
    tax: 'Taxe',
    gross: 'Brut',
    expenses: 'Frais',
    details: 'Coordonnées',
    journey: 'Trajet',

    departureDate: 'Date de départ',

    cmacRef: 'Réf CMAC',

    yourRef: 'Votre réf',

    invoiceAccepted: 'Facture validée et acceptée',

    invoiceDoesNotMatch: 'Veuillez examiner et soumettre à nouveau',

    stillUpload: 'Vous pouvez télécharger cette facture, mais elle devra être vérifiée.',
    paymentDelayed: 'Le paiement n’interviendra pas avant la fin de cette procédure.',
    totalNetDoesNotMatch:
      'Le montant Total Net sur la facture ne correspond pas au prix total net de tous les trajets sélectionnés.',

    expected: 'Attendu',

    totalGross: 'Brut total',

    totalNet: 'Net total',

    totalTax: 'Taxe totale',

    notFound: 'Non trouvé',

    invoiced: 'Facturé',

    underDispute: 'Litige en cours',

    notUnique: 'Un fichier avec ce nom existe déjà. Veuillez choisir un nom de fichier différent.',
    invalidCharacters:
      'Le nom du fichier contient des caractères invalides. Les éléments suivants ne sont pas autorisés.',
    invalidFileExtension:
      'Ce type de fichier nest pas valide. Les types de fichiers suivants sont autorisés.',

    cancelled: 'Annulé',

    journeyCost: 'Coût du trajet (Net)',

    leadMilesCost: 'Coût des miles supplémentaires (Net)',

    waitingTimeCost: "Coût du temps d'attente (Net)",

    expensesCost: 'Coût des dépenses (Net)',

    greenFeeCost: 'Coût des frais écologiques (Net)',

    totalCost: 'Coût total (Net)',

    downloadJourneyData: 'Cliquez ici pour télécharger les données des trajets sélectionnés.',

    paid: 'Payé',
    excludedJourneys: 'Ce processus exclut les trajets actuellement contestés.',
    rejectedStatus: 'Rejetée',
    createPdfInvoice: 'Créer Facture PDF',
    createPdf: {
      title: 'Créer une facture PDF pour les trajets sélectionnés',
      invoiceNumber: 'Numéro de Facture',
      invoiceDate: 'Date de la Facture',
      chooseAddress: 'Veuillez choisir une adresse',
      registerDetails: {
        title: 'Détails de l’Inscription',
        heading:
          'Pour créer votre facture, nous avons besoin de quelques informations supplémentaires.',
        financial: {
          heading: 'Veuillez suivre les étapes ci-dessous pour ajouter vos détails financiers :',
          step1:
            'Sélectionnez l’icône du menu hamburger {x} située en haut à droite de votre écran',
          step2: "Ensuite, sélectionnez 'Profil'.",
          step3:
            "Dans la section 'Détails Financiers', cliquez sur le bouton d’édition {x} et entrez vos informations.",
        },
        address: {
          heading:
            'Veuillez suivre les étapes ci-dessous pour ajouter l’adresse de votre entreprise :',
          step1:
            'Sélectionnez l’icône du menu hamburger {x} située en haut à droite de votre écran.',
          step2: "Ensuite, sélectionnez 'Profil'.",
          step3:
            "Dans la section 'Adresses', cliquez sur le bouton plus {x} et entrez vos informations.",
        },
      },
    },
    separateInvoicesRequired:
      'Les voyages avec des véhicules de plus de 10 sièges doivent être facturés séparément des voyages avec des véhicules de moins de 10 sièges.',
    invoiceNumberFieldNameLabel: 'Nom du champ du numéro de facture',
    totalNetAmountFieldNameLabel: 'Nom du champ Montant net total',
  },
  compliance: {
    introduction:
      "Ci-dessous se trouve un résumé des principaux documents qui doivent être en place et enregistrés auprès de nous. Si vous avez des questions concernant l'un de ces documents, veuillez nous contacter au 03333 207 100",
    noRulesFound: 'Aucune règle de conformité trouvée',
  },
  contactItem: {
    generalManager: 'Directeur général',
    reception24Hour: 'Réception 24 heures sur 24',
    revenueSales: 'Revenus / Ventes',
    twentyFourHourEmail: 'E-mail 24 heures sur 24',
    notification: 'Notification',
    other: 'Autre',
  },
}
